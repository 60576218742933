import { Formik, Field, ErrorMessage, useField } from "formik";
import { Container, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { eMax, eMin, eRequired } from "../../utils/validation_messages";
import { Stack } from "react-bootstrap";
import ProfilePageContainer from "../../common/ProfilePageContainer";
import Sidebar from "../../common/Sidebar";
import { applicantRequest } from "../company_applicants/api";
import { BeError, parseErrorToSweetAlert } from "../../utils/api_utils";
import { BrowserQRCodeReader } from "@zxing/library";
import { useState } from "react";
import TargButton from "../../common/TargButton";
import styles from "../company_applicants/Applicants.module.css";
import scanStyles from "./scan.module.css";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { getLanguagePreference } from "../../utils/general_utils";

const CustomTextArea = ({ label, ...props }: any) => {
  const [field] = useField(props);
  return (
    <>
      <textarea className={scanStyles.textarea} {...field} {...props} />
    </>
  );
};
export default function Scan() {
  const { t } = useTranslation();
  const [scanning, setScanning] = useState(false);
  const [reader, setReader] = useState(new BrowserQRCodeReader());
  const lgBreakpoint = useMediaQuery({ query: "(max-width: 992px)" });

  const scan = async (setFieldValue: any) => {
    if (!scanning) {
      setScanning(true);
      const res = await reader.decodeOnceFromConstraints(
        {
          video: { facingMode: "environment" },
        },
        "video"
      );
      console.log(res.getText());
      setFieldValue("code", res.getText() || "", true);
      // await sleep(200);
    }

    // reset scanner if it finds a result, or if scanning is set to false
    setScanning(false);
    reader.reset();
    setReader(new BrowserQRCodeReader());
  };

  return (
    <Stack direction={lgBreakpoint ? "vertical" : "horizontal"}>
      <Sidebar />
      <ProfilePageContainer>
        <Stack className="text-center mb-4">
          <h1 className="mb-4">{t("Scan_Title")}</h1>
          <Formik
            initialValues={{
              code: "",
              note: "",
            }}
            validationSchema={Yup.object({
              code: Yup.string()
                .min(6, eMin(t, t("Scan_Code"), 6))
                .max(6, eMax(t, t("Scan_Code"), 6))
                .required(eRequired(t, t("Scan_Code"))),
              note: Yup.string()
                .max(5000, eMax(t, t("Scan_Notes"), 5000))
                .optional(),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                values.code = values.code.toUpperCase();
                const result = await applicantRequest(values);
                await Swal.fire({
                  icon: "success",
                  title: result.message[getLanguagePreference()],
                });
                window.location.reload();
              } catch (error: any) {
                console.error(error);
                Swal.fire(parseErrorToSweetAlert(t, error as BeError));
              }
              setSubmitting(false);
            }}
          >
            {({ handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <h4>{t("Scan_CodeEnter")}</h4>
                <Form.Group className={`mt-2`}>
                  <Container>
                    <Field
                      as={Form.Control}
                      name="code"
                      type="text"
                      className={`mx-auto d-inline ${scanStyles.customCodeField}`}
                      placeholder="A1B2C3"
                    />
                    <p
                      className="d-inline"
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      {t("Scan_Or")}
                    </p>
                    <TargButton
                      variant="green"
                      className={`d-inline ml-1`}
                      onClick={() => {
                        scan(setFieldValue);
                      }}
                    >
                      {!scanning ? t("Scan_QR") : t("Scan_CameraStop")}
                    </TargButton>
                  </Container>
                  <ErrorMessage
                    className={styles.err}
                    component="div"
                    name="code"
                  />
                </Form.Group>
                <Form.Group className="mt-2">
                  <h4>{t("Scan_NoteAdd")}</h4>
                  <CustomTextArea
                    as={Form.Control}
                    name="note"
                    cols={40}
                    rows={5}
                    placeholder="Add a note..."
                  />
                  <ErrorMessage
                    className={styles.err}
                    component="div"
                    name="note"
                  />
                </Form.Group>
                <TargButton
                  variant="green"
                  type="submit"
                  className="mt-3 p-2"
                  styling={{ minWidth: "180px" }}
                >
                  {t("Scan_RegisterButton")}
                </TargButton>
                <div className="my-2 w-100">
                  {scanning && <video className={styles.vid} id="video" />}
                </div>
              </Form>
            )}
          </Formik>
        </Stack>
      </ProfilePageContainer>
    </Stack>
  );
}
