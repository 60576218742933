import axios from "axios";
import { getAuthHeader } from "../../utils/auth";

export interface QuizSubmission {
  score: number;
  quizSubmitted: boolean;
}

export interface Company {
  quizz: { id: number; maxScore: number; time: number };
  id: number;
  name: string;
  logoUrl: string;
  descriptionRo: string;
  descriptionEn: string;
  url: string;
}

export interface Application {
  quizSubmission?: QuizSubmission;
  company: Company;
}

export async function getApplicationsRequest(): Promise<Application[]> {
  // daca ceva merge rau va arunca o eroare, altfel este totul bine :) await axios.post(`${process.env.REACT_APP_BE_URL}/contact`, data);
  const result = await axios.get(
    `${process.env.REACT_APP_BE_URL}/profile/applications`,
    getAuthHeader()
  );
  return result.data as Application[];
}
