import { Navbar } from "react-bootstrap";
import { sanityCheck } from "./api";
import { useQuery } from "react-query";

export default function SanityCheck() {
  const { data } = useQuery("sanity-check", sanityCheck);

  return (
    <>
      <Navbar />
      <h2>{data}</h2>
    </>
  );
}
