import axios from "axios";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { BeError, parseErrorToSweetAlert } from "../../utils/api_utils";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getLanguagePreference } from "../../utils/general_utils";

export default function ConfirmationAccount() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const data = { token: token };
        const result = await axios.post(
          `${process.env.REACT_APP_BE_URL}/auth/checkAccount`,
          data
        );

        Swal.fire({
          icon: "success",
          text: result.data.message[getLanguagePreference()],
        });
        nav("/login");
      } catch (error: any) {
        console.error(error);
        Swal.fire(parseErrorToSweetAlert(t, error as BeError));
        nav("/");
      }
    };

    verifyToken();
  }, [token]);

  return (
    <Stack>
      <Header />
      <h1 className="m-auto">{t("ConfirmationAccount_Title")}</h1>
      <Footer />
    </Stack>
  );
}
