import axios from "axios";
import { LangObj } from "../../utils/api_utils";

interface RegisterUserRequestProps {
  firstName: string;
  lastName: string;
  email: string;
  faculty: string;
  year: string;
  password: string;
  password_confirmation: string;
  tags: string;
}

interface RegisterUserResponse {
  message: LangObj;
}

export async function registerParticipant(
  data: RegisterUserRequestProps
): Promise<RegisterUserResponse> {
  let result = await axios.post(
    `${process.env.REACT_APP_BE_URL}/auth/register`,
    data
  );
  return result.data as RegisterUserResponse;
}

interface LoginUserRequestProps {
  email: string;
  password: string;
}

type LoginUserResponse = {
  ttl: string;
  token: string;
};

export async function loginParticipant(
  data: LoginUserRequestProps
): Promise<LoginUserResponse> {
  let result = await axios.post(
    `${process.env.REACT_APP_BE_URL}/auth/login`,
    data
  );
  return result.data as LoginUserResponse;
}
