import { Container, Row, Col, Image } from "react-bootstrap";
import TargButton from "../../common/TargButton";
import styles from "./Hero.module.css";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { EffectCoverflow, Autoplay } from "swiper";
import { useMediaQuery } from "react-responsive";
import { checkRole, isLoggedIn, Role } from "../../utils/auth";
import { useTranslation } from "react-i18next";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const carouselPictures = [
  "poza_carousel1.jpg",
  "poza_carousel2.jpg",
  "poza_carousel3.jpg",
  "poza_carousel4.jpg",
  "poza_carousel5.jpg",
  "poza_carousel6.jpg",
];

// https://www.figma.com/file/SWHby6nl2RlaGnOwxRJSDd/Site?node-id=4%3A203&t=4z9Ab57qIZ5FP8YH-0
export default function Hero() {
  const { t } = useTranslation();
  const collapseCarousel = useMediaQuery({ query: "(max-width: 1200px)" });
  const nav = useNavigate();

  return (
    <>
      <span id="home" className="section"></span>
      <Container>
        <Row>
          <Col
            md={6}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div className="text-center">
              <Image className="p-1" height={200} src="/logo.svg" />
              <h2 className={styles.date}>{t("Hero_Date")}</h2>
              <h2>{t("Hero_Location")}</h2>
            </div>
          </Col>
          <Col
            md={6}
            className={`d-flex flex-column align-items-center ${styles.info}`}
          >
            <h1 className="w-100">{t("Hero_Title")}</h1>
            <p>{t("Hero_Description")}</p>
            <div
              style={{
                marginRight: "auto",
                textAlign: "left",
                marginBottom: "0px",
              }}
            >
              <>
                {t("Hero_Time")}
                <ul>
                  <li
                    dangerouslySetInnerHTML={{ __html: t("Hero_TimeSaturday") }}
                  />
                  <li
                    dangerouslySetInnerHTML={{ __html: t("Hero_TimeSunday") }}
                  />
                </ul>
              </>
            </div>
			<p>{t("Hero_Alert")}</p>
            <Container fluid>
              {!isLoggedIn() && (
                <Row>
                  <Col xs={12} md={6} className={`py-3 ${styles.leftbtn}`}>
                    <TargButton
                      variant="green"
                      className="w-100"
                      onClick={async () => {
                        nav("/");
                        await sleep(50);
                        nav("/login");
                      }}
                    >
                      {t("Hero_Login")}
                    </TargButton>
                  </Col>
                  <Col xs={12} md={6} className={`py-3 ${styles.rightbtn}`}>
                    <TargButton
                      variant="green"
                      className="w-100"
                      onClick={async () => {
                        nav("/");
                        await sleep(50);
                        nav("/register");
                      }}
                    >
                      {t("Hero_Register")}
                    </TargButton>
                  </Col>
                </Row>
              )}
              {isLoggedIn() && (
                <Row>
                  {checkRole(Role.COMPANY) && (
                    <Col xs={12} md={6} className={`py-3 ${styles.leftbtn}`}>
                      <TargButton
                        variant="green"
                        className="w-100"
                        onClick={() => nav("/profile/scan")}
                      >
                        {t("Hero_CodeScan")}
                      </TargButton>
                    </Col>
                  )}
                  {checkRole(Role.USER) && (
                    <Col xs={12} md={6} className={`py-3 ${styles.leftbtn}`}>
                      <TargButton
                        variant="green"
                        className="w-100"
                        onClick={() => nav("/profile/code")}
                      >
                        {t("Hero_Code")}
                      </TargButton>
                    </Col>
                  )}
                  {/* <Col xs={12} md={6} className={`py-3 ${styles.rightbtn}`}>
                    <TargButton
                      variant="green"
                      className="w-100"
                      onClick={() => window.location.assign("/harta.pdf")}
                    >
                      {t("Hero_Map")}
                    </TargButton>
                  </Col> */}
                </Row>
              )}
            </Container>
          </Col>
        </Row>
      </Container>

      <Container className="mt-5">
        <Swiper
          className={styles.carousel}
          slidesPerView={!collapseCarousel ? 2.4 : 1.5}
          centeredSlides
          loop
          autoplay={{
            delay: 2500,
          }}
          modules={[EffectCoverflow, Autoplay]}
          effect={"coverflow"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 300,
            slideShadows: false,
          }}
        >
          {carouselPictures.map((p, index) => (
            <SwiperSlide key={index}>
              <Image className={styles.carouselImage} src={p} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </>
  );
}
