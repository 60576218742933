import axios from "axios";
import { getAuthHeader } from "../../utils/auth";
import Quiz from "./QuizPage";

export interface StartQuizResponse {
  submission: Submission;
  quizz: Quiz;
}

export interface QuizAnswer {
  text: string;
  selectedOptions: { optionId: number }[];
  questionId: number;
}

export interface Submission {
  quizAnswers: QuizAnswer[];
  score: number;
  quizSubmitted: boolean;
  createdAt: string;
}

export interface Quiz {
  time: number;
  company: Company;
  maxScore: number;
  questions: Question[];
  createdAt: string;
}

export interface Question {
  id: number;
  text: string;
  type: "MULTIPLE" | "SINGLE" | "OPEN"; // change to enum (MULTIPLE, SINGLE, OPEN)
  options: Option[];
  imgUrl?: string;
}

interface Company {
  name: string;
}

export interface Option {
  id: number;
  isCorrect?: boolean;
  text: string;
}

export interface CreateAnswerDto {
  questionId: number;
  text?: string;
  selectedOptions: number[];
}

export interface SubmitQuizDto {
  answers: CreateAnswerDto[];
  final?: boolean;
}

type QuizResponse = Quiz;

export async function startQuiz(id: number): Promise<StartQuizResponse> {
  let result = await axios.post(
    `${process.env.REACT_APP_BE_URL}/quizzes/${id}/start`,
    "",
    getAuthHeader()
  );
  return result.data as StartQuizResponse;
}

export async function submitQuiz(
  id: number,
  quiz: SubmitQuizDto
): Promise<QuizResponse> {
  console.log(quiz);
  let result = await axios.post(
    `${process.env.REACT_APP_BE_URL}/quizzes/${id}/submit`,
    quiz,
    getAuthHeader()
  );
  return result.data;
}
