import { Container } from "react-bootstrap";
import styles from "./ProfilePageContainer.module.css";

export default function ProfilePageContainer({ children }: any) {
  return (
    <Container
      fluid
      className={`d-flex justify-content-center align-items-center my-auto ${styles.bigContainer}`}
    >
      <Container
        fluid
        className={`${styles.contentContainer} shadow border border-top-0 m-lg-4 mx-1 my-3 px-2 py-4`}
      >
        {children}
      </Container>
    </Container>
  );
}
