import { useParams } from "react-router-dom";
import { ErrorMessage, Field, Formik } from "formik";
import { Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import {
  eEmail,
  eFirstName,
  eLastName,
  eMax,
  eMin,
  ePassConfirmation,
  eRequired,
} from "../../utils/validation_messages";
import stylesError from "../../common/Inputs.module.css";
import TargButton from "../../common/TargButton";
import { BeError, parseErrorToSweetAlert } from "../../utils/api_utils";
import Swal from "sweetalert2";
import { createCompanyAccount, CreateCompanyAccountDto } from "./api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLanguagePreference } from "../../utils/general_utils";

function Input({
  name,
  type,
  placeholder,
  translateKey,
}: {
  name: keyof CreateCompanyAccountDto;
  type: "password" | "email" | "text";
  placeholder?: string;
  translateKey: string;
}) {
  const { t } = useTranslation();
  return (
    <Form.Group controlId={name} className="my-2">
      <Form.Label className="mx-auto">
        {t("RegisterCompanyForm_" + translateKey)}
      </Form.Label>
      <Field
        as={Form.Control}
        name={name}
        type={type}
        placeholder={placeholder}
      />
      <ErrorMessage component="div" className={stylesError.error} name={name} />
    </Form.Group>
  );
}

export default function RegisterCompanyForm() {
  const { t } = useTranslation();
  let { token } = useParams();
  const nav = useNavigate();

  function errHandler() {
    Swal.fire({
      icon: "error",
      title: t("RegisterCompanyForm_SwalTitleInvalidLink"),
    });
    nav("/");
  }

  if (!token) errHandler();

  return (
    <>
      {token && (
        <Formik
          initialValues={{
            email: "",
            lastName: "",
            firstName: "",
            password: "",
            password_confirmation: "",
          }}
          validationSchema={Yup.object({
            firstName: Yup.string()
              .matches(
                /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,2}[.]{0,1}$/,
                eFirstName(t)
              )
              .max(100, eMax(t, t("RegisterCompanyForm_FirstNameReq"), 100))
              .required(eRequired(t, t("RegisterCompanyForm_FirstNameReq"))),
            lastName: Yup.string()
              .matches(
                /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,2}[.]{0,1}$/,
                eLastName(t)
              )
              .max(100, eMax(t, t("RegisterCompanyForm_LastNameReq"), 100))
              .required(eRequired(t, t("RegisterCompanyForm_LastNameReq"))),
            email: Yup.string()
              .email(eEmail(t))
              .max(100, eMax(t, t("RegisterCompanyForm_EmailReq"), 100))
              .required(eRequired(t, t("RegisterCompanyForm_EmailReq"))),
            password: Yup.string()
              .min(8, eMin(t, t("RegisterCompanyForm_PasswordReq"), 8))
              .max(100)
              .required(
                eRequired(t, t("RegisterCompanyForm_PasswordReq"), true)
              ),
            password_confirmation: Yup.string()
              .oneOf([Yup.ref("password")], ePassConfirmation(t))
              .required(
                eRequired(
                  t,
                  t("RegisterCompanyForm_PasswordConfirmationReq"),
                  true
                )
              ),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              if (token === undefined) {
                errHandler();
                return;
              }
              const res = await createCompanyAccount({ ...values, token });
              Swal.fire({
                icon: "success",
                title: t("RegisterCompanyForm_SwalTitleSuccess"),
                text: res.message[getLanguagePreference()],
              });
              nav("/login");
            } catch (error: any) {
              console.error(error);
              Swal.fire(parseErrorToSweetAlert(t, error as BeError));
            }
            setSubmitting(false);
          }}
        >
          {(formik) => (
            <Form
              onSubmit={formik.handleSubmit}
              className="d-flex flex-column justify-content-center"
            >
              <Input
                name="email"
                type="email"
                placeholder="john@company.com"
                translateKey="Email"
              />
              <Input name="firstName" type="text" translateKey="FirstName" />
              <Input name="lastName" type="text" translateKey="LastName" />
              <Input name="password" type="password" translateKey="Password" />
              <Input
                name="password_confirmation"
                type="password"
                translateKey="PasswordConfirmation"
              />

              {!formik.isSubmitting ? (
                <TargButton variant="green" className="m-4" type="submit">
                  {t("RegisterCompanyForm_Register")}
                </TargButton>
              ) : (
                <Spinner className="mx-auto my-4" />
              )}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
