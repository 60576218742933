import { useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import TargButton from "../../common/TargButton";
import { getPartners, Partner } from "./api";
import styles from "./Partners.module.css";
import { useMediaQuery } from "react-responsive";
import parse from "html-react-parser";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { getLanguagePreference } from "../../utils/general_utils";

function truncateHtml(htmlText: string): {
  result: string;
  truncated: boolean;
} {
  const maxim = 350;
  if (htmlText.length < maxim) return { result: htmlText, truncated: false };
  return { result: htmlText.substring(0, maxim), truncated: true };
}

// https://xd.adobe.com/view/56109ec9-b893-49f8-9590-cb7f686404f5-0343/screen/525723cf-4f56-407a-a90a-196626b5879f
// https://xd.adobe.com/view/56109ec9-b893-49f8-9590-cb7f686404f5-0343/screen/d022c640-995c-4a1f-ac2a-e182ef53e27a
export default function Partners() {
  const { t } = useTranslation();
  const [partners, setPartners] = useState<Partner[]>([]);
  const [showPartnerDetails, setShowPartnerDetails] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<Partner | undefined>(
    undefined
  );
  const mdBreakpoint = useMediaQuery({ query: "(max-width: 768px)" });

  useQuery("partners", getPartners, {
    onSuccess: (data) => {
      data.sort((a, b) => a.name.localeCompare(b.name));
      setPartners(data);
    },
  });

  function PartnerCard(p: Partner) {
    const { result, truncated } = truncateHtml(
      getLanguagePreference() === "ro"
        ? p.descriptionRo || p.descriptionEn || ""
        : p.descriptionEn || p.descriptionRo || ""
    );

    return (
      <Col
        xs={12}
        sm={6}
        md={6}
        lg={p.contractType === "PREMIUM" ? 4 : 3}
        className="p-3"
      >
        <Card className={`${styles.premiumCard} h-100`}>
          {p.contractType === "PREMIUM" ? (
            <>
              <Card.Img
                onClick={() => {
                  setSelectedPartner(p);
                  setShowPartnerDetails(true);
                }}
                src={p.logoUrl}
                className="py-4 px-5 rounded"
              />
              <Card.Title
                onClick={() => {
                  setSelectedPartner(p);
                  setShowPartnerDetails(true);
                }}
              >
                {p.name}
              </Card.Title>
            </>
          ) : (
            <>
              <Card.Title
                onClick={() => {
                  setSelectedPartner(p);
                  setShowPartnerDetails(true);
                }}
              >
                {p.name}
              </Card.Title>
              <Card.Img
                onClick={() => {
                  setSelectedPartner(p);
                  setShowPartnerDetails(true);
                }}
                src={p.logoUrl}
                className={
                  "py-4 px-5 rounded m-auto " +
                  (p.contractType === "MEDIA" ? `${styles.defaultCursor}` : "")
                }
              />
            </>
          )}
          {p.contractType === "PREMIUM" && !mdBreakpoint && (
            <Card.Body style={{ textAlign: "center" }}>
              {parse(result)}
              {truncated && "..."}
            </Card.Body>
          )}

          {p.contractType !== "MEDIA" ? (
            <TargButton
              variant="green"
              onClick={() => {
                setSelectedPartner(p);
                setShowPartnerDetails(true);
              }}
              className="mx-5 my-3"
            >
              {t("Partners_Details")}
            </TargButton>
          ) : (
            <></>
          )}
        </Card>
      </Col>
    );
  }

  return (
    <>
      <span id="premium" className="section"></span>
      <Container className="text-center">
        <h1 className="">{t("Partners_Premium")}</h1>
        <Row className="justify-content-center">
          {partners
            .filter((p) => p.contractType === "PREMIUM")
            .map((p, index) => (
              <PartnerCard key={index} {...p} />
            ))}
        </Row>

        <span id="partners" className="section"></span>
        <h1 className="mt-5">{t("Partners_Companies")}</h1>

        <Container className={styles.partnerContainer} fluid>
          <h2 className="mb-5">{t("Partners_Gold")}</h2>
          <Row>
            {partners
              .filter((p) => p.contractType === "GOLD")
              .map((p, index) => (
                <PartnerCard key={index} {...p} />
              ))}
          </Row>
        </Container>

        <Container className={styles.partnerContainer} fluid>
          <h2 className="mb-5">{t("Partners_Silver")}</h2>
          <Row>
            {partners
              .filter((p) => p.contractType === "SILVER")
              .map((p, index) => (
                <PartnerCard key={index} {...p} />
              ))}
          </Row>
        </Container>

        <Container className={`${styles.partnerContainer} mb-0`} fluid>
          <h2 className="mb-5">{t("Partners_Bronze")}</h2>
          <Row>
            {partners
              .filter((p) => p.contractType === "BRONZE")
              .map((p, index) => (
                <PartnerCard key={index} {...p} />
              ))}
          </Row>
        </Container>

        <Container className={`${styles.partnerContainer} mb-0 mt-5`} fluid>
          <h2 className="mb-5">{t("Partners_Media")}</h2>
          <Row>
            <>
              {(() => {
                const p = partners.find((p) => p.name === "Europa FM");
                if (!p) return;
                return <PartnerCard {...p} />;
              })()}
              {(() => {
                const p = partners.find((p) => p.name === "RRA");
                if (!p) return;
                return <PartnerCard {...p} />;
              })()}
              {partners
                .filter(
                  (p) =>
                    p.contractType === "MEDIA" &&
                    p.name !== "Europa FM" &&
                    p.name !== "RRA"
                )
                .map((p, index) => (
                  <PartnerCard key={index} {...p} />
                ))}
            </>
          </Row>
        </Container>
      </Container>

      {selectedPartner?.contractType !== "MEDIA" ? (
        <Modal
          size="lg"
          show={showPartnerDetails}
          onHide={() => setShowPartnerDetails(false)}
          scrollable
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedPartner?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {parse(
                getLanguagePreference() === "ro"
                  ? selectedPartner?.descriptionRo ||
                      selectedPartner?.descriptionEn ||
                      ""
                  : selectedPartner?.descriptionEn ||
                      selectedPartner?.descriptionRo ||
                      ""
              )}
            </p>
            <p>
              {t("Partners_Link")}
              <a href={selectedPartner?.url}>link.</a>
            </p>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}
