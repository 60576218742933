export function eMax(
  t: (key: string) => string,
  text: string,
  size: number
): string {
  return `${text} ${t("ValidationMessages_eMax_1")} ${size} ${t(
    "ValidationMessages_eMax_2"
  )}`;
}

export function eMin(
  t: (key: string) => string,
  text: string,
  size: number
): string {
  return `${text} ${t("ValidationMessages_eMin_1")} ${size} ${t(
    "ValidationMessages_eMin_2"
  )}`;
}

export function eFilesize(
  t: (key: string) => string,
  text: string,
  sizeInMB: number
): string {
  return `${text} ${t("ValidationMessages_eFilesize")} ${sizeInMB} MB`;
}

export function eRequired(
  t: (key: string) => string,
  text: string,
  feminine?: boolean
): string {
  return `${text} ${
    feminine
      ? t("ValidationMessages_eRequiredFeminine")
      : t("ValidationMessages_eRequiredMasculine")
  }`;
}

export function ePassConfirmation(t: (key: string) => string): string {
  return t("ValidationMessages_ePasswordConfirmation");
}

export function eEmail(t: (key: string) => string): string {
  return t("ValidationMessages_eEmail");
}

export function eFirstName(t: (key: string) => string): string {
  return t("ValidationMessages_eFirstName");
}

export function eLastName(t: (key: string) => string): string {
  return t("ValidationMessages_eLastName");
}

export function eFacultyName(t: (key: string) => string): string {
  return t("ValidationMessages_eFacultyName");
}

export function eSelectMin(
  t: (key: string) => string,
  text: string,
  size: number
): string {
  return `${t("ValidationMessages_eSelectMin")} ${size} ${text}`;
}
