import { Col, Container, Row } from "react-bootstrap";
import styles from "./Statistics.module.css";
import { useTranslation } from "react-i18next";

// https://xd.adobe.com/view/56109ec9-b893-49f8-9590-cb7f686404f5-0343/screen/d9072e7d-f8d1-41d1-847e-f9ba76e849b4
export default function Statistics() {
  const { t } = useTranslation();
  // TODO: make request to BE for the actual data
  const data = {
    companies: 60,
    participants: 4000,
    applications: 25000,
  };

  return (
    <>
      <span id="statistics" className="section"></span>
      <Container className="text-center">
        <h1>{t("Statistics_Edition")}</h1>
        <Row>
          <Col className="my-3" xs={12} lg={6}>
            <div className={`${styles.dataPoint} gap-2`}>
              <h3>{data.companies}+</h3>
              <p>{t("Statistics_Companies")}</p>
            </div>
          </Col>
          <Col className="my-3" xs={12} lg={6}>
            <div className={`${styles.dataPoint} gap-2`}>
              <h3>{Math.round(data.participants / 100) * 100}+</h3>
              <p>{t("Statistics_Students")}</p>
            </div>
          </Col>
          <Col className="my-3" xs={12}>
            <div className={`${styles.dataPoint} gap-2`}>
              <h3>{Math.round(data.applications / 1000) * 1000}+</h3>
              <p>{t("Statistics_Applications")}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
