import { CSSProperties } from "react";
import { Button } from "react-bootstrap";
import styles from "./TargButton.module.css";

interface TargButtonProps {
  onClick?: (() => void) | ((e: any) => void);
  variant: "green" | "white";
  children: any;
  className?: string;
  styling?: CSSProperties;

  type?: "submit" | "button";
  disabled?: boolean;
}

export default function TargButton({
  onClick,
  variant,
  children,
  className,
  styling,
  disabled,
  ...props
}: TargButtonProps) {
  if (className) className += ` ${styles.targBtn}`;
  else className = styles.targBtn;
  className += " shadow";

  return (
    <Button disabled={disabled} onClick={onClick} className={className} {...props} style={styling}>
      {children}
    </Button>
  );
}
