import { Formik } from "formik";
import { Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { eEmail, eMax, eMin, eRequired } from "../../utils/validation_messages";
import TargButton from "../../common/TargButton";
import { TextInput } from "../../common/Inputs";
import { forgotPassword } from "./api";
import { BeError, parseErrorToSweetAlert } from "../../utils/api_utils";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { getLanguagePreference } from "../../utils/general_utils";

export default function ForgotPasswordForm() {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .max(100, eMax(t, t("ForgotPasswordForm_Email"), 100))
          .min(1, eMin(t, t("ForgotPasswordForm_Email"), 1))
          .email(eEmail(t))
          .required(eRequired(t, t("ForgotPasswordForm_Email"))),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const res = await forgotPassword(values);
          Swal.fire({
            icon: "success",
            title: t("ForgotPasswordForm_SwalTitle"),
            text: res.message[getLanguagePreference()],
          });
        } catch (error: any) {
          console.error(error);
          Swal.fire(parseErrorToSweetAlert(t, error as BeError));
        }
        setSubmitting(false);
      }}
    >
      {(formik) => (
        <Form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column justify-content-center"
        >
          <Form.Group controlId="email" className="text-center">
            <TextInput
              label={t("ForgotPasswordForm_Email")}
              name="email"
              type="text"
              placeholder={t("ForgotPasswordForm_EmailPlaceholder")}
            />
          </Form.Group>
          {!formik.isSubmitting ? (
            <TargButton variant="green" className="m-4" type="submit">
              {t("ForgotPasswordForm_Submit")}
            </TargButton>
          ) : (
            <Spinner className="mx-auto my-4" />
          )}
        </Form>
      )}
    </Formik>
  );
}
