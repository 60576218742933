import axios from "axios";

interface ContactRequestProps {
  name: string;
  email: string;
  message: string;
}

export async function contactRequest(data: ContactRequestProps): Promise<void> {
  // daca ceva merge rau va arunca o eroare, altfel este totul bine :) await axios.post(`${process.env.REACT_APP_BE_URL}/contact`, data);
  await axios.post(`${process.env.REACT_APP_BE_URL}/contacts`, data);
}

export type Contract = "BRONZE" | "SILVER" | "GOLD" | "PREMIUM" | "MEDIA";

export interface Partner {
  name: string;
  url: string;
  descriptionRo?: string;
  descriptionEn?: string;
  contractType: Contract;
  logoUrl: string;
}

export async function getPartners(): Promise<Partner[]> {
  // daca ceva merge rau va arunca o eroare, altfel este totul bine :) await axios.post(`${process.env.REACT_APP_BE_URL}/contact`, data);
  const result = await axios.get(`${process.env.REACT_APP_BE_URL}/companies`);
  return result.data as Partner[];
}
