import { Stack } from "react-bootstrap";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import RegisterCompanyForm from "./RegisterCompanyForm";
import { useTranslation } from "react-i18next";

export default function RegisterCompany() {
  const { t } = useTranslation();

  return (
    <Stack>
      <Header />
      <Stack className="justify-content-center align-items-center">
        <h2 className="m-5">{t("RegisterCompany_Title")}</h2>
        <RegisterCompanyForm />
      </Stack>
      <Footer />
    </Stack>
  );
}
