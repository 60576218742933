// Sidebarul de aici: https://www.figma.com/file/SWHby6nl2RlaGnOwxRJSDd/Site?node-id=4%3A2567&t=4z9Ab57qIZ5FP8YH-0
import { Container, Navbar, Image, Nav } from "react-bootstrap";
import styles from "./Sidebar.module.css";
import { ReactComponent as ProfileIcon } from "./assets/icon-profile.svg";
import { ReactComponent as ApplicationsIcon } from "./assets/icon-aplicari.svg";
import { ReactComponent as QRIcon } from "./assets/qrcode.svg";
import { ReactComponent as LangIcon } from "./assets/lang.svg";
import { ReactComponent as LogoutIcon } from "./assets/icon-logout.svg";
import { ReactComponent as HomeIcon } from "./assets/icon-home.svg";
import { useMediaQuery } from "react-responsive";
import { checkRole, logout, Role } from "../utils/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { switchLanguage } from "../utils/general_utils";

export default function Sidebar() {
  const { t, i18n } = useTranslation();
  const nav = useNavigate();
  const lgBreakpoint = useMediaQuery({ query: "(max-width: 992px)" });

  return (
    <Navbar
      className={`${styles.sidebar} ${!lgBreakpoint ? "fixed-top" : ""}`}
      bg="white"
      expand="lg"
    >
      <Container
        className="h-100 px-0 pt-lg-5 flex-lg-column align-items-start"
        fluid
      >
        <Navbar.Brand href="/" className="px-3 mx-lg-auto">
          <Image
            className="p-1"
            width={!lgBreakpoint ? 170 : 95}
            src="/logo.svg"
          />
        </Navbar.Brand>
        <div className="p-3 my-auto">
          <Navbar.Toggle className="p-1" />
        </div>

        <Navbar.Collapse
          className="w-100"
          style={lgBreakpoint ? undefined : { overflowY: "auto" }}
        >
          <Nav className="flex-column w-100 align-items-start pt-3">
            <Nav.Link className={styles.sidebarLink} href="/">
              <div>
                <HomeIcon width={24} />
                <span className="mx-4">{t("Sidebar_Home")}</span>
              </div>
            </Nav.Link>

            <Nav.Link className={styles.sidebarLink} href="/profile">
              <div>
                <ProfileIcon width={24} />
                <span className="mx-4">{t("Sidebar_Profile")}</span>
              </div>
            </Nav.Link>

            <Nav.Link
              className={styles.sidebarLink}
              href={checkRole(Role.USER) ? "/profile/code" : "/profile/scan"}
            >
              <div>
                <QRIcon width={24} height={24} />
                <span className="mx-4">
                  {checkRole(Role.USER) ? t("Sidebar_Code") : t("Sidebar_Scan")}
                </span>
              </div>
            </Nav.Link>

            <Nav.Link
              className={styles.sidebarLink}
              href={
                checkRole(Role.USER)
                  ? "/profile/applications"
                  : "/profile/applicants"
              }
            >
              <div>
                <ApplicationsIcon width={24} />
                <span className="mx-4">
                  {checkRole(Role.USER)
                    ? t("Sidebar_Applications")
                    : t("Sidebar_Applicants")}
                </span>
              </div>
            </Nav.Link>

            <Nav.Link className={styles.sidebarLink} onClick={switchLanguage}>
              <div>
                <LangIcon width={24} />
                <span className="mx-4">{i18n.language.toUpperCase()}</span>
              </div>
            </Nav.Link>

            <Nav.Link
              className={styles.sidebarLink}
              onClick={() => {
                logout();
                nav("/");
              }}
            >
              <div>
                <LogoutIcon width={24} />
                <span className="mx-4">{t("Sidebar_Logout")}</span>
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
