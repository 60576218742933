import ProfilePageContainer from "../../common/ProfilePageContainer";
import { Stack, Spinner, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import TargButton from "../../common/TargButton";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Formik } from "formik";
import Swal, { SweetAlertResult } from "sweetalert2";
import { BeError, parseErrorToSweetAlert } from "../../utils/api_utils";
import {
  getApplicantRequest,
  getMyCVs,
  getAllCVs,
  ApplicantResponseDataDto,
  Application,
  getCsvApplicants,
  updateNote,
  ApplicantResponseMetadataDto,
  getFilteredCvs,
} from "./api";
import styles from "./Applicants.module.css";
import { getCV } from "../profile/api";
import { generateInitialValues, QuestionField } from "../quiz/common";
import fileDownload from "js-file-download";
import {
  useMaterialReactTable,
  MRT_ColumnDef,
  MaterialReactTable,
  MRT_PaginationState,
} from "material-react-table";
import { useTranslation } from "react-i18next";
import { getLanguagePreference } from "../../utils/general_utils";

interface TableRow {
  applications: Application;
  maxScore: number;
}

export default function Applicants() {
  const { t } = useTranslation();
  const tableHeader = t("Applicants_HeaderTable", {
    returnObjects: true,
  }) as string[];
  const quizHeader = t("Applicants_HeaderQuiz", {
    returnObjects: true,
  }) as string[];
  const [applicationData, setApplicationData] =
    useState<ApplicantResponseDataDto>();
  const [cvIsDownloading, setCvIsDownloading] = useState<boolean[]>([]);
  const [bigDownload, setBigDownload] = useState<boolean>(false);
  const [csvDownloading, setCsvDownloading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [selectedAppplication, setSelectedApplication] =
    useState<Application>();
  const [showApplication, setShowApplication] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({
    quizScore: false,
    quizResponses: false,
    cvKeywords: false,
  });
  const [applicationMetadata, setApplicationMetadata] =
    useState<ApplicantResponseMetadataDto>({ count: 0, pages: 0, page: 0 });
  const [filteredParticipantIds, setFilteredParticipantIds] = useState<number[]>([]);

  const mapApiDataToTableRow: () => (
    value: Application,
    index: number,
    array: Application[]
  ) => TableRow = () => {
    return (a) => {
      return {
        applications: a,
        maxScore:
          applicationData !== undefined && applicationData.quizz !== null
            ? applicationData.quizz.maxScore
            : 0,
      } as TableRow;
    };
  };

  const handleUpdateNotes = (
    a: Application
  ): ((value: SweetAlertResult<any>) => void) => {
    return async (result: any) => {
      if (result.isConfirmed) {
        try {
          const res = await updateNote(a.id, result.value);

          if (res.status === 200) {
            await Swal.fire({
              icon: "success",
              title: t("Applicants_SwalTitleNoteEditSuccess"),
            });

            window.location.reload();
          }
        } catch (error: any) {
          console.error(error);
          Swal.fire(parseErrorToSweetAlert(t, error as BeError));
        }
      }
    };
  };

  useQuery(
    ["applicants", pagination.pageIndex, pagination.pageSize],
    async () => {
      const applicants = await getApplicantRequest(
        pagination.pageIndex + 1,
        pagination.pageSize
      );
      return applicants;
    },
    {
      onSuccess: (data) => {
        setApplicationData(data.data);
        setApplicationMetadata(data.meta);
        setCvIsDownloading(data.data.applications.map(() => false));
        setColumnVisibility({
          quizScore: data.data.quizz !== null,
          quizResponses: data.data.quizz !== null,
          cvKeywords: false,
        });
      },
      onError: (error: any) => {
        Swal.fire(parseErrorToSweetAlert(t, error as BeError));
      },
    }
  );

  const columns = useMemo<MRT_ColumnDef<TableRow>[]>(
    () => [
      {
        accessorFn: (row) =>
          `${row.applications.participant.firstName} ${row.applications.participant.lastName}`,
        id: "name",
        header: tableHeader[0],
      },
      {
        accessorKey: "applications.participant.faculty",
        id: "faculty",
        header: tableHeader[1],
      },
      {
        accessorKey: "applications.participant.year",
        id: "year",
        header: tableHeader[2],
      },
      {
        accessorKey: "applications.participant.email",
        id: "email",
        header: tableHeader[3],
      },
      {
        accessorFn: (row) => Boolean(row.applications.participant.CVId),
        id: "cv",
        filterVariant: "checkbox",
        header: tableHeader[4],
        Cell: ({ cell, row, staticRowIndex }) => {
          const i = staticRowIndex !== undefined ? staticRowIndex : 0;
          const b = row.original.applications;
          return b.participant.CVId ? (
            <a
              href="#1"
              onClick={async () => {
                let temp = [...cvIsDownloading];
                temp[i] = true;
                setCvIsDownloading(temp);
                await getCV(b.participant.CVId, b.participant.originalCVName);
                temp = [...cvIsDownloading];
                temp[i] = false;
                setCvIsDownloading(temp);
              }}
            >
              {!cvIsDownloading[i] ? (
                t("Applicants_CVUploaded")
              ) : (
                <Spinner className="mx-auto" />
              )}
            </a>
          ) : (
            <>{t("Applicants_CVNotUploaded")}</>
          );
        },
      },
      {
        accessorFn: (row) => row,
        id: "note",
        header: tableHeader[5],
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const a = cell.getValue<TableRow>().applications;
          return (
            <div className="mx-auto">
              <TargButton
                variant="green"
                className="m-3 "
                onClick={async () => {
                  await Swal.fire({
                    title: `${t("Applicants_SwalTitleNoteEdit")} ${
                      a.participant.firstName
                    } ${a.participant.lastName}`,
                    input: "textarea",
                    showCancelButton: true,
                    inputValue: a.note,
                    inputPlaceholder: t("Applicants_SwalPlaceholderNoteEdit"),
                    confirmButtonText: t("Applicants_SwalConfirmNoteEdit"),
                    confirmButtonColor: "#1d911d",
                  }).then(handleUpdateNotes(a));
                }}
              >
                {t("Applicants_NoteEdit")}
              </TargButton>
              <TargButton
                variant="green"
                className="m-3 "
                onClick={async () => {
                  await Swal.fire({
                    title: `${t("Applicants_SwalTitleNoteSee")} ${
                      a.participant.firstName
                    } ${a.participant.lastName}`,
                    html:
                      a.note.length !== 0
                        ? a.note
                        : t("Applicants_SwalTitleNoteSeeEmpty"),
                  });
                }}
              >
                {t("Applicants_NoteSee")}
              </TargButton>
            </div>
          );
        },
      },
      {
        accessorFn: (row) =>
          row.applications.participant.CVKeywords.split(";").join(" "),
        id: "cvKeywords",
        header: tableHeader[6],
      },
      {
        accessorFn: (row) => row,
        id: "quizScore",
        header: quizHeader[0],
        Cell: ({ cell }) => {
          const row = cell.getValue<TableRow>();
          const a = row.applications;

          return a.quizSubmission && a.quizSubmission.quizSubmitted ? (
            <>
              {a.quizSubmission.score} / {row.maxScore}
            </>
          ) : (
            <>{t("Applicants_QuizNotTaken")}</>
          );
        },
      },
      {
        accessorFn: (row) => row,
        id: "quizResponses",
        header: quizHeader[1],
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const a = cell.getValue<TableRow>().applications;

          return a.quizSubmission && a.quizSubmission.quizSubmitted ? (
            <TargButton
              variant="green"
              onClick={() => {
                setSelectedApplication(a);
                setShowApplication(true);
              }}
            >
              {t("Applicants_QuizSee")}
            </TargButton>
          ) : (
            <>{t("Applicants_QuizNotTaken")}</>
          );
        },
      },
      {
        accessorFn: (row) =>
          row.applications.participant.tags.split(";").join(", "),
        id: "tags",
        header: tableHeader[7],
      },
    ],
    []
  );
  const table = useMaterialReactTable({
    data: applicationData
      ? applicationData.applications.map(mapApiDataToTableRow())
      : [],
    columns,
    onPaginationChange: setPagination,
    enablePagination: true,
    enableBottomToolbar: true,
    manualPagination: true,
    paginationDisplayMode: "pages",
    rowCount: applicationMetadata.pages * applicationMetadata.count,
	muiPaginationProps: {
		rowsPerPageOptions: [5, 10, 15, 20, 25, 30, 50, 100, 1000],
	},
    state: {
      pagination,
      columnVisibility,
    },
    globalFilterFn: "includesString",
	renderTopToolbarCustomActions: ({ table }) => (
		<div className="d-flex ms-auto my-auto align-items-center gap-2">
		  <TargButton className={`${(table.getState().globalFilter && filteredParticipantIds.length > 0) ? '' : styles.offButton} shadow-none`} disabled={!(table.getState().globalFilter && filteredParticipantIds.length > 0)} variant="green" onClick={async () => {
				setBigDownload(true);
				const data = await getFilteredCvs(filteredParticipantIds);
					Swal.fire({
					icon: "info",
					text: data.message[getLanguagePreference()],
				});
				setBigDownload(false);
		  }}>
			{(table.getState().globalFilter && filteredParticipantIds.length > 0) ? t('Applicants_CVsDownloadFiltered') : t('Applicants_CVsDownloadFilteredOff')}
		  </TargButton>
		</div>
	  ),
  });

  useEffect(() => {
	const filteredRows = table.getFilteredRowModel().rows;
	const participantIds = filteredRows.map((row) => row.original.applications.participant.id);
	setFilteredParticipantIds(participantIds);
  }, [
	table.getState().globalFilter,
	table.getState().columnFilters,
	table.getFilteredRowModel().rows.length,
  ]);

  return (
    <ProfilePageContainer>
      <h1 className="w-100 text-center mb-3">{t("Applicants_Applicants")}</h1>
      <Stack className="text-center mb-4">
        <h4 className="mt-4">{t("Applicants_CVsDownload")}</h4>
        {!bigDownload ? (
          <TargButton
            variant="green"
            className="mt-3 p-2 mx-auto"
            styling={{ minWidth: "180px", maxWidth: "400px" }}
            onClick={async () => {
              setBigDownload(true);
              const data = await getMyCVs();
              Swal.fire({
                icon: "info",
                text: data.message[getLanguagePreference()],
              });
              setBigDownload(false);
            }}
          >
            {t("Applicants_Download")}
          </TargButton>
        ) : (
          <Spinner className="mx-auto" />
        )}

        <h4 className="mt-4">{t("Applicants_ExportCSV")}</h4>
        {!csvDownloading ? (
          <TargButton
            variant="green"
            className="mt-3 p-2 mx-auto"
            styling={{ minWidth: "180px", maxWidth: "400px" }}
            onClick={async () => {
              setCsvDownloading(true);
              const { csv } = await getCsvApplicants();
              fileDownload(csv, "participants.csv");
              setCsvDownloading(false);
            }}
          >
            {t("Applicants_Download")}
          </TargButton>
        ) : (
          <Spinner className="mx-auto" />
        )}

        {applicationData?.allCVs && (
          <>
            <h4 className="mt-4">{t("Applicants_CVsDownloadAll")}</h4>
            {!bigDownload ? (
              <TargButton
                variant="green"
                className="mt-3 p-2 mx-auto"
                styling={{ minWidth: "180px", maxWidth: "400px" }}
                onClick={async () => {
                  setBigDownload(true);
                  const data = await getAllCVs();
                  Swal.fire({
                    icon: "info",
                    text: data.message[getLanguagePreference()],
                  });
                  setBigDownload(false);
                }}
              >
                {t("Applicants_Download")}
              </TargButton>
            ) : (
              <Spinner className="mx-auto" />
            )}
          </>
        )}
      </Stack>

      <h4 className="mt-5 mb-3 mx-auto text-center">
        {t("Applicants_Applicants")}
      </h4>
      <h4 className="mx-2 text-danger" style={{ fontSize: ".8rem" }}>
        {t("Applicants_SearchCV")}
      </h4>
      <MaterialReactTable table={table} />
      {selectedAppplication && applicationData && (
        <Modal
          size="xl"
          show={showApplication}
          onHide={() => setShowApplication(false)}
          scrollable
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {`${selectedAppplication?.participant.lastName} ${selectedAppplication?.participant.firstName}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={generateInitialValues({
                submission: selectedAppplication.quizSubmission,
                quizz: applicationData.quizz,
              })}
              enableReinitialize
              onSubmit={() => {}}
            >
              {({ handleSubmit, values }) => (
                <Form className={styles.form} onSubmit={handleSubmit}>
                  <fieldset disabled>
                    <Stack gap={3} className="pl-3">
                      {applicationData.quizz.questions.map((q, index) => (
                        <QuestionField
                          key={q.id}
                          question={q}
                          index={index}
                          values={values}
                        />
                      ))}
                    </Stack>
                  </fieldset>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      )}
    </ProfilePageContainer>
  );
}
