import { Row, Col, Image, Form, Spinner, Stack } from "react-bootstrap";
import ProfilePageContainer from "../../common/ProfilePageContainer";
import {
  TextInput,
  SelectInput,
  FileInput,
  ToggleInput,
} from "../../common/Inputs";
import { useState } from "react";
import {
  CompanyAccount,
  getCV,
  getProfile,
  ParticipantAccount,
  updateProfile,
  deleteProfile,
  updateCompanyProfile,
  deleteCompanyProfile,
} from "./api";
import { logout } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import styles from "./Info.module.css";
import { ErrorMessage, FieldArray, Formik } from "formik";
import * as Yup from "yup";
import {
  eFilesize,
  eMax,
  eRequired,
  eFirstName,
  eLastName,
  eFacultyName,
  eSelectMin,
} from "../../utils/validation_messages";
import Swal from "sweetalert2";
import { BeError, parseErrorToSweetAlert } from "../../utils/api_utils";
import errors from "../../common/Inputs.module.css";
import TargButton from "../../common/TargButton";
import { YEARS } from "../../utils/general_utils";
import { useMediaQuery } from "react-responsive";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { tags } from "../../utils/constants";

interface FormFields {
  firstName?: string;
  lastName?: string;
  faculty?: string;
  facultyDropDown?: number;
  year?: string;
  cv?: Blob;
  tags?: string[];
}

// https://xd.adobe.com/view/56109ec9-b893-49f8-9590-cb7f686404f5-0343/screen/a5c5f96f-bb0f-49e2-9510-986a7ccf91f6
export default function Info() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const mdBreakpoint = useMediaQuery({ query: "(max-width: 768px)" });
  const [initialValues, setInitialValues] = useState<FormFields>({
    firstName: undefined,
    lastName: undefined,
    faculty: undefined,
    facultyDropDown: -1,
    year: undefined,
    cv: undefined,
    tags: Array(tags.length).fill(""),
  });
  const [user, setUser] = useState<
    ParticipantAccount | CompanyAccount | undefined
  >(undefined);
  const faculties = t("Info_Faculties", { returnObjects: true }) as string[];

  useQuery("profile", getProfile, {
    onSuccess: (data) => {
      setUser(data);
      if (data?.role === "USER") {
        const facIndex = faculties.findIndex((e) => e === data.faculty);
        setInitialValues({
          firstName: data?.firstName,
          lastName: data?.lastName,
          faculty: data?.faculty,
          facultyDropDown: facIndex !== -1 ? facIndex : faculties.length - 1,
          year: data?.year,
          tags: tags.map((tag) => (data?.tags.includes(tag) ? tag : "")),
        });
      } else if (data?.role === "COMPANY") {
        setInitialValues({
          firstName: data?.firstName,
          lastName: data?.lastName,
        });
      }
    },
    onError: () => {
      // this mean that the user is unauthorized
      Swal.fire({
        icon: "error",
        text: t("Info_Relogin"),
      });
      logout();
      nav("/");
    },
  });

  const deleteAccount = async () => {
    try {
      if (user?.role === "COMPANY") await deleteCompanyProfile();
      else if (user?.role === "USER") await deleteProfile();
      logout();
      nav("/");
    } catch (error: any) {
      console.error(error);
      Swal.fire(parseErrorToSweetAlert(t, error as BeError));
    }
  };

  function EditBtn() {
    return (
      <Image
        className={styles.editBtn}
        src="/edit_btn.png"
        width={30}
        height={30}
        onClick={() => setShowForm(true)}
      />
    );
  }

  return (
    <ProfilePageContainer>
      <h1 className="w-100 text-center my-4">{t("Info_Title")}</h1>
      {user && (
        <Formik
          initialValues={initialValues}
          enableReinitialize // necessary for updating the initial values
          validationSchema={
            user?.role === "USER"
              ? Yup.object({
                  firstName: Yup.string()
                    .matches(
                      /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,2}[.]{0,1}$/,
                      eFirstName(t)
                    )
                    .max(100, eMax(t, t("Info_FirstName"), 100))
                    .required(eRequired(t, t("Info_FirstName"))),
                  lastName: Yup.string()
                    .matches(
                      /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,2}[.]{0,1}$/,
                      eLastName(t)
                    )
                    .max(100, eMax(t, t("Info_LastName"), 100))
                    .required(eRequired(t, t("Info_LastName"))),
                  facultyDropDown: Yup.number()
                    .oneOf(faculties.map((_, i) => i))
                    .required(eRequired(t, t("Info_Faculty"))),
                  faculty: Yup.string().when(
                    ["facultyDropDown"],
                    (facultyDropDown) => {
                      if (Number(facultyDropDown) === faculties.length - 1) {
                        return Yup.string()
                          .matches(
                            /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,7}[.]{0,1}$/,
                            eFacultyName(t)
                          )
                          .max(100)
                          .required(eRequired(t, t("Info_Faculty")));
                      }

                      return Yup.string().optional();
                    }
                  ),
                  year: Yup.string()
                    .oneOf([
                      "I",
                      "II",
                      "III",
                      "IV",
                      "MASTER1",
                      "MASTER2",
                      "DOCTORAT",
                      "OTHER",
                    ])
                    .required(eRequired(t, t("Info_Year"))),
                  cv: Yup.mixed()
                    .test(
                      "size",
                      eFilesize(t, t("Info_CV"), 5),
                      (val) => !val || val?.size < 5_000_000
                    )
                    .test(
                      "format",
                      t("Info_FileFormat"),
                      (val) => !val || val?.type === "application/pdf"
                    ),
                  tags: Yup.array().test(
                    "tags",
                    eSelectMin(t, t("Info_TagsSelect"), 3),
                    (toggledTags) =>
                      toggledTags !== undefined &&
                      toggledTags.filter((tag) => tag && tags.includes(tag))
                        .length >= 3
                  ),
                })
              : user?.role === "COMPANY" &&
                Yup.object({
                  firstName: Yup.string()
                    .matches(
                      /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,2}[.]{0,1}$/,
                      eFirstName(t)
                    )
                    .max(100, eMax(t, t("Info_FirstName"), 100))
                    .required(eRequired(t, t("Info_FirstName"))),
                  lastName: Yup.string()
                    .matches(
                      /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,2}[.]{0,1}$/,
                      eLastName(t)
                    )
                    .max(100, eMax(t, t("Info_LastName"), 100))
                    .required(eRequired(t, t("Info_LastName"))),
                })
          }
          onSubmit={async (values, { setSubmitting }) => {
            try {
              if (user?.role === "USER") {
                if (Number(values.facultyDropDown) !== faculties.length - 1)
                  values.faculty = faculties[values.facultyDropDown!];
                const data = {
                  ...values,
                  tags: values.tags?.filter((tag) => tag !== "").join(";"),
                };
                await updateProfile(data);
              } else if (user?.role === "COMPANY") {
                await updateCompanyProfile(values);
              }
              window.location.reload();
            } catch (error: any) {
              console.error(error);
              Swal.fire(parseErrorToSweetAlert(t, error as BeError));
            }
            setSubmitting(false);
          }}
        >
          {(formik) => (
            <Form className="p-4" onSubmit={formik.handleSubmit}>
              <Row className={styles.dataRow}>
                <Col className="p-0">{t("Info_LastName")}</Col>
                {!showForm ? (
                  <>
                    <Col xs={8}>{user?.lastName}</Col>

                    <Col
                      xs={1}
                      className="d-flex p-0 justify-content-end align-items-center"
                    >
                      <EditBtn />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={9}>
                      <TextInput
                        name="lastName"
                        type="text"
                        placeholder={t("Info_LastName")}
                      />
                    </Col>
                  </>
                )}
              </Row>

              <Row className={styles.dataRow}>
                <Col className="p-0">{t("Info_FirstName")}</Col>
                {!showForm ? (
                  <>
                    <Col xs={8}>{user?.firstName}</Col>

                    <Col
                      xs={1}
                      className="d-flex p-0 justify-content-end align-items-center"
                    >
                      <EditBtn />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={9}>
                      <TextInput
                        name="firstName"
                        type="text"
                        placeholder={t("Info_FirstName")}
                      />
                    </Col>
                  </>
                )}
              </Row>

              <Row className={styles.dataRow}>
                <Col className="p-0">{t("Info_Email")}</Col>
                <Col xs={9}>{user?.email}</Col>
              </Row>

              {user?.role === "USER" && (
                <>
                  <Row className={styles.dataRow}>
                    <Col className="p-0">{t("Info_Faculty")}</Col>
                    {!showForm ? (
                      <>
                        <Col xs={8}>{user?.faculty}</Col>
                        <Col
                          xs={1}
                          className="d-flex p-0 justify-content-end align-items-center"
                        >
                          <EditBtn />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={9}>
                          <SelectInput
                            label={t("Info_FacultyChoose")}
                            name="facultyDropDown"
                          >
                            {faculties.map((e, index) => (
                              <option key={index} value={index}>
                                {e}
                              </option>
                            ))}
                          </SelectInput>
                          {Number(formik.values.facultyDropDown) ===
                          faculties.length - 1 ? (
                            <Col xs={9}>
                              <TextInput
                                name="faculty"
                                type="text"
                                placeholder={t("Info_Faculty")}
                              />
                            </Col>
                          ) : (
                            <></>
                          )}
                          <ErrorMessage
                            component="div"
                            className={errors.error}
                            name="facultyDropDown"
                          />
                        </Col>
                      </>
                    )}
                  </Row>

                  <Row className={styles.dataRow}>
                    <Col className="p-0">{t("Info_Year")}</Col>
                    {!showForm ? (
                      <>
                        <Col xs={8}>{YEARS[user?.year]}</Col>
                        <Col
                          xs={1}
                          className="d-flex p-0 justify-content-end align-items-center"
                        >
                          <EditBtn />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={9}>
                          <SelectInput label={t("Info_YearChoose")} name="year">
                            <option value="">{t("Info_YearChoose")}</option>
                            <option value="I">{t("Info_YearB1")}</option>
                            <option value="II">{t("Info_YearB2")}</option>
                            <option value="III">{t("Info_YearB3")}</option>
                            <option value="IV">{t("Info_YearB4")}</option>
                            <option value="MASTER1">{t("Info_YearM1")}</option>
                            <option value="MASTER2">{t("Info_YearM2")}</option>
                            <option value="DOCTORAT">{t("Info_YearP")}</option>
                            <option value="OTHER">{t("Info_Other")}</option>
                          </SelectInput>
                          <ErrorMessage
                            component="div"
                            className={errors.error}
                            name="year"
                          />
                        </Col>
                      </>
                    )}
                  </Row>

                  <Row className={styles.dataRow}>
                    <Col className="p-0">CV</Col>
                    {!showForm ? (
                      <>
                        <Col xs={8}>
                          {user.originalCVName ? (
                            <a
                              href="#download"
                              onClick={() =>
                                getCV(user?.CVId, user?.originalCVName)
                              }
                            >
                              {user.originalCVName}
                            </a>
                          ) : (
                            <span>{t("Info_CVNotUploaded")}</span>
                          )}
                        </Col>
                        <Col
                          xs={1}
                          className="d-flex p-0 justify-content-end align-items-center"
                        >
                          <EditBtn />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={9}>
                          <FileInput
                            name="cv"
                            setFieldValue={formik.setFieldValue}
                          />
                        </Col>
                      </>
                    )}
                  </Row>

                  <Row className={styles.dataRow}>
                    <Col className="p-0 d-flex align-items-center">
                      {t("Info_Tags")}
                    </Col>
                    {!showForm ? (
                      <>
                        <Col xs={8} className="overflow-auto me-3">
                          <Row className={`${styles.tagRow}`}>
                            {user?.tags &&
                              user?.tags
                                .split(";")
                                .map(
                                  (tag, index) =>
                                    index % 2 === 0 && (
                                      <ToggleInput
                                        key={index}
                                        className={`m-1 px-2 py-1 ${mdBreakpoint}`}
                                        label={tag}
                                        isToggled={true}
                                      />
                                    )
                                )}
                          </Row>
                          <Row className={`${styles.tagRow}`}>
                            {user?.tags &&
                              user?.tags
                                .split(";")
                                .map(
                                  (tag, index) =>
                                    index % 2 === 1 && (
                                      <ToggleInput
                                        key={index}
                                        className={`m-1 px-2 py-1 ${mdBreakpoint}`}
                                        label={tag}
                                        isToggled={true}
                                      />
                                    )
                                )}
                          </Row>
                        </Col>
                        <Col
                          xs={1}
                          className="d-flex p-0 justify-content-end align-items-center"
                        >
                          <EditBtn />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={9} className="overflow-auto me-3">
                          <FieldArray
                            name="tags"
                            render={(arrayHelpers) => (
                              <>
                                <Row className={`${styles.tagRow}`}>
                                  {tags.map(
                                    (tag, index) =>
                                      index % 2 === 0 && (
                                        <ToggleInput
                                          key={index}
                                          className={`m-1 px-2 py-1 ${mdBreakpoint}`}
                                          label={tag}
                                          isToggled={
                                            formik.values.tags![index] !== ""
                                          }
                                          onClick={() => {
                                            if (
                                              formik.values.tags![index] === ""
                                            )
                                              arrayHelpers.replace(index, tag);
                                            else
                                              arrayHelpers.replace(index, "");
                                          }}
                                        />
                                      )
                                  )}
                                </Row>
                                <Row className={`${styles.tagRow}`}>
                                  {tags.map(
                                    (tag, index) =>
                                      index % 2 === 1 && (
                                        <ToggleInput
                                          key={index}
                                          className={`m-1 px-2 py-1 ${mdBreakpoint}`}
                                          label={tag}
                                          isToggled={
                                            formik.values.tags![index] !== ""
                                          }
                                          onClick={() => {
                                            if (
                                              formik.values.tags![index] === ""
                                            )
                                              arrayHelpers.replace(index, tag);
                                            else
                                              arrayHelpers.replace(index, "");
                                          }}
                                        />
                                      )
                                  )}
                                </Row>
                              </>
                            )}
                          />
                          <ErrorMessage
                            component="div"
                            className={errors.error}
                            name="tags"
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              )}

              {user?.role === "COMPANY" && (
                <Row className={styles.dataRow}>
                  <Col className="p-0">{t("Info_Company")}</Col>
                  <Col xs={8}>{user?.company.name}</Col>
                  <Col className="d-flex p-0 justify-content-end" xs={1}></Col>
                </Row>
              )}

              <Row
                className={`${styles.btnRow} p-4 d-flex justify-content-center`}
              >
                {!showForm ? (
                  <Stack>
                    <TargButton
                      variant="green"
                      className={`m-2 ${mdBreakpoint && "w-75 mx-auto"}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowForm(true);
                      }}
                    >
                      {t("Info_ProfileChange")}
                    </TargButton>
                    <TargButton
                      className={`m-2 ${mdBreakpoint && "w-75 mx-auto"}`}
                      variant="green"
                      onClick={async () => {
                        const res = await Swal.fire({
                          title: t("Info_AccountDeleteWarningTitle"),
                          text: t("Info_AccountDeleteWarningText"),
                          showCancelButton: true,
                          showConfirmButton: true,
                          confirmButtonColor: "#d33",
                          confirmButtonText: "Confirm",
                        });

                        if (res.isConfirmed) deleteAccount();
                      }}
                    >
                      {t("Info_AccountDelete")}
                    </TargButton>
                  </Stack>
                ) : (
                  <Stack
                    direction={!mdBreakpoint ? "horizontal" : "vertical"}
                    className="justify-content-center"
                  >
                    {!formik.isSubmitting ? (
                      <>
                        <TargButton
                          variant="green"
                          className={`m-2 ${mdBreakpoint && "w-75 mx-auto"}`}
                          type="submit"
                        >
                          {t("Info_Save")}
                        </TargButton>
                        <TargButton
                          variant="green"
                          className={`m-2 ${mdBreakpoint && "w-75 mx-auto"}`}
                          onClick={() => setShowForm(false)}
                        >
                          {t("Info_Cancel")}
                        </TargButton>
                      </>
                    ) : (
                      <Spinner className="mx-auto my-4" />
                    )}
                  </Stack>
                )}
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </ProfilePageContainer>
  );
}
