import { Stack, Image, Container } from "react-bootstrap";
import styles from "./Footer.module.css";
import { useTranslation } from "react-i18next";

// https://www.figma.com/file/SWHby6nl2RlaGnOwxRJSDd/Site?node-id=4%3A2340&t=noQU92JCpUlTCfrB-0
export default function Footer() {
  const { t } = useTranslation();

  return (
    <Container fluid className={`${styles.footer} mt-auto p-4`}>
      <Stack
        className="justify-content-center mb-5"
        direction="horizontal"
        gap={4}
      >
        <a href="https://acs.pub.ro/" target="_blank" rel="noreferrer">
          <Image src="/acs.png" width={60} height={60} />
        </a>
        <a href="https://lsacbucuresti.ro/" target="_blank" rel="noreferrer">
          <img style={{ maxWidth: "200px" }} src="/lsac_logo.svg" alt="" />
        </a>
      </Stack>

      <Stack className="justify-content-center" direction="horizontal" gap={5}>
        <a
          href="https://www.facebook.com/LsacBucuresti/"
          target="_blank"
          rel="noreferrer"
        >
          <Image src="/fb.png" width={40} height={40} />
        </a>
        <a
          href="https://www.instagram.com/targ.lsacbucuresti/"
          target="_blank"
          rel="noreferrer"
        >
          <Image src="/ig.png" width={40} height={40} />
        </a>
        <a
          href="https://www.youtube.com/@LSACBucuresti"
          target="_blank"
          rel="noreferrer"
        >
          <Image src="/yt.png" width={40} height={40} />
        </a>
      </Stack>

      <Stack className="justify-content-center mt-4" direction="horizontal">
        <p>{t("Footer_Copyright")}</p>
      </Stack>
    </Container>
  );
}
