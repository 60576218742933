import axios from "axios";
import { LangObj } from "../../utils/api_utils";

interface ForgotPasswordProps {
  email: string;
}

interface ForgotPasswordResponse {
  message: LangObj;
}

export async function forgotPassword(
  data: ForgotPasswordProps
): Promise<ForgotPasswordResponse> {
  let result = await axios.post(
    `${process.env.REACT_APP_BE_URL}/auth/forgot-password`,
    data
  );
  return result.data as ForgotPasswordResponse;
}
