import { Stack } from "react-bootstrap";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import ResetPasswordForm from "./ResetPasswordForm";
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
  const { t } = useTranslation();

  return (
    <Stack>
      <Header />
      <Stack className="justify-content-center align-items-center">
        <h2 className="m-5">{t("ResetPassword_Title")}</h2>
        <ResetPasswordForm />
      </Stack>
      <Footer />
    </Stack>
  );
}
