import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import {
  eMin,
  ePassConfirmation,
  eRequired,
} from "../../utils/validation_messages";
import TargButton from "../../common/TargButton";
import { TextInput } from "../../common/Inputs";
import { BeError, parseErrorToSweetAlert } from "../../utils/api_utils";
import Swal from "sweetalert2";
import { resetPassword } from "./api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ResetPasswordForm() {
  const { t } = useTranslation();
  let { token } = useParams();
  const nav = useNavigate();
  if (!token) return <h1>{t("ResetPasswordForm_WrongLink")}</h1>;

  return (
    <Formik
      initialValues={{
        password: "",
        password_confirmation: "",
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(8, eMin(t, t("ResetPasswordForm_Password"), 8))
          .max(100)
          .required(eRequired(t, t("ResetPasswordForm_Password"), true)),
        password_confirmation: Yup.string()
          .oneOf([Yup.ref("password")], ePassConfirmation(t))
          .required(
            eRequired(t, t("ResetPasswordForm_PasswordConfirmation"), true)
          ),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        if (!token) return;
        try {
          await resetPassword({ ...values, token });
          Swal.fire({
            icon: "success",
            title: t("ResetPasswordForm_SwalTitle"),
            text: t("ResetPasswordForm_SwalText"),
          });
          nav("/");
        } catch (error: any) {
          console.error(error);
          Swal.fire(parseErrorToSweetAlert(t, error as BeError));
        }
        setSubmitting(false);
      }}
    >
      {(formik) => (
        <Form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column justify-content-center"
        >
          <Form.Group className="m-3" controlId="password">
            <TextInput
              label={t("ResetPasswordForm_Password")}
              name="password"
              type="password"
              placeholder={t("ResetPasswordForm_Password")}
            />
          </Form.Group>

          <Form.Group className="m-3" controlId="password_confirmation">
            <TextInput
              label={t("ResetPasswordForm_PasswordConfirmation")}
              name="password_confirmation"
              type="password"
              placeholder={t("ResetPasswordForm_PasswordConfirmation")}
            />
          </Form.Group>

          {!formik.isSubmitting ? (
            <TargButton variant="green" className="m-4" type="submit">
              {t("ResetPasswordForm_PasswordReset")}
            </TargButton>
          ) : (
            <Spinner className="mx-auto my-4" />
          )}
        </Form>
      )}
    </Formik>
  );
}
