import { Formik } from "formik";
import { Form, Button, Stack, Spinner } from "react-bootstrap";
import { TextInput } from "../../common/Inputs";
import * as Yup from "yup";
import { loginParticipant } from "./api";
import { setToken } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import { BeError, parseErrorToSweetAlert } from "../../utils/api_utils";
import Swal from "sweetalert2";
import { eEmail, eMin, eRequired } from "../../utils/validation_messages";
import styles from "./AuthForm.module.css";
import { useTranslation } from "react-i18next";

export default function LoginForm() {
  const { t } = useTranslation();
  let nav = useNavigate();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(eEmail(t))
          .max(100)
          .required(eRequired(t, t("LoginForm_EmailReq"))),
        password: Yup.string()
          .min(8, eMin(t, t("LoginForm_PasswordReq"), 8))
          .max(100)
          .required(eRequired(t, t("LoginForm_PasswordReq"), true)),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const res = await loginParticipant(values);
          setToken(res.token);
          nav("/profile");
        } catch (error: any) {
          console.error(error);
          Swal.fire(parseErrorToSweetAlert(t, error as BeError));
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form className={styles.form} onSubmit={handleSubmit}>
          <Stack gap={3}>
            <TextInput
              label="Email"
              name="email"
              type="email"
              placeholder="andrei@gmail.com"
              popupForm
              padding
            />

            <TextInput
              label={t("LoginForm_Password")}
              name="password"
              type="password"
              placeholder="*****"
              popupForm
              padding
            />

            <Stack gap={3} className={styles.btnContainer}>
              {!isSubmitting ? (
                <>
                  <Button className={styles.btn} type="submit">
                    {t("LoginForm_Login")}
                  </Button>

                  <Button
                    className={styles.btn}
                    onClick={() => {
                      nav("/forgot-password");
                      window.location.reload();
                    }}
                  >
                    {t("LoginForm_PasswordForgot")}
                  </Button>
                </>
              ) : (
                <Spinner className="mx-auto my-4" />
              )}
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
