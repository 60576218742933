import { ErrorMessage, Field, FieldInputProps } from "formik";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import styles from "./Inputs.module.css";

interface TextInputProps {
  label?: string;
  name: string;
  type: "text" | "email" | "password";
  placeholder: string;
  rows?: number;
  popupForm?: boolean;
  padding?: boolean;
}

export function TextInput({
  label,
  name,
  type,
  placeholder,
  rows,
  popupForm,
  padding,
}: TextInputProps) {
  let groupClassname = "";
  if (padding) groupClassname += `${styles.inputGroup}`;
  if (popupForm) groupClassname += ` ${styles.popupForm}`;

  return (
    <Form.Group className={groupClassname} controlId={name}>
      {popupForm ? (
        <FloatingLabel label={label}>
          <Field
            as={Form.Control}
            name={name}
            type={type}
            placeholder={placeholder}
            rows={rows} // Passed only if defined
            className="form-control"
          />
        </FloatingLabel>
      ) : (
        <>
          {label && <Form.Label>{label}</Form.Label>}
          <Field
            as={rows ? "textarea" : Form.Control}
            name={name}
            type={type}
            placeholder={placeholder}
            rows={rows} // Passed only if defined
            className="form-control"
          />
        </>
      )}
      <ErrorMessage component="div" className={styles.error} name={name} />
    </Form.Group>
  );
}

interface SelectInputProps {
  label: string;
  name: string;
  children: any;
  popupForm?: boolean;
  padding?: boolean;
}

export function SelectInput({
  popupForm,
  name,
  children,
  padding,
}: SelectInputProps) {
  let groupClassname = "";
  if (padding) groupClassname += `${styles.inputGroup}`;
  if (popupForm) groupClassname += ` ${styles.popupForm}`;

  return (
    <Form.Group className={groupClassname} controlId={name}>
      <Field as={Form.Select} name={name}>
        {children}
      </Field>
      <ErrorMessage component="div" className={styles.error} name={name} />
    </Form.Group>
  );
}

interface FileInputProps {
  name: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  label?: string;
  popupForm?: boolean;
  padding?: boolean;
}

export function FileInput({
  name,
  setFieldValue,
  label,
  popupForm,
  padding,
}: FileInputProps & Partial<FieldInputProps<any>>) {
  let groupClassname = "";
  if (padding) groupClassname += `${styles.inputGroup}`;
  if (popupForm) groupClassname += ` ${styles.popupForm}`;

  return (
    <Form.Group className={groupClassname} controlId={name}>
      {label && <Form.Label className={styles.fileLabel}>{label}</Form.Label>}
      <input
        type="file"
        name={name}
        accept="application/pdf"
        onChange={(e) => {
          setFieldValue(name, e.currentTarget.files?.[0]);
        }}
      />
      <ErrorMessage component="div" className={styles.error} name={name} />
    </Form.Group>
  );
}

interface ToggleInputProps {
  className?: string;
  label: string;
  isToggled: boolean;
  swapColors?: boolean;
  onClick?: () => void;
}

export function ToggleInput({
  className,
  label,
  isToggled,
  swapColors,
  onClick,
}: ToggleInputProps) {
  if (className)
    className += ` ${swapColors ? styles.toggleBtnReverse : styles.toggleBtn}`;
  else className = `${styles.toggleBtn}`;
  if (isToggled)
    className += ` ${swapColors ? styles.toggledReverse : styles.toggled}`;

  return (
    <Button
      onClick={onClick}
      className={className}
      disabled={onClick === undefined}
    >
      {label}
    </Button>
  );
}
