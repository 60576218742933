export const tags = [
  // maximum 'dbChar()' characters(tag1+tag2+...) time from tags field of schema.prisma
  "Internship",
  "Full-time",
  "Part-time",
  "On-site",
  "Hybrid",
  "Remote",
  "AI / ML",
  "Web",
  "Mobile",
  "Embedded",
  "Cyber",
  "Data",
  "DevOps",
  "UI / UX",
  "QA",
];
