import ProfilePageContainer from "../../common/ProfilePageContainer";
import { Table, Image, Modal } from "react-bootstrap";
import TargButton from "../../common/TargButton";
import { useState } from "react";
import { Application, Company, getApplicationsRequest } from "./api";
import Swal from "sweetalert2";
import { BeError, parseErrorToSweetAlert } from "../../utils/api_utils";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";
import { useQuery } from "react-query";

import styles from "./UserApplication.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLanguagePreference } from "../../utils/general_utils";

const BearingPointId = 32;
const CognizantSoftvisionId = 15;
const NxpId = 43;
const SoftserveId = 77;
const snowflakeIds = [
  BearingPointId,
  CognizantSoftvisionId,
  NxpId,
  SoftserveId,
];

function isSnowflake(companyId: number) {
  return snowflakeIds.some((id) => id === companyId);
}

function RenderSnowflake({ companyId }: { companyId: number }) {
  const { t } = useTranslation();
  const text = t("ApplicationInfo_QuizLink");
  if (companyId === BearingPointId) {
    return (
      <a href="https://swarm.bearingpoint.services/#/checklist/6a1c8ad8-3a4e-42ca-bf1e-51e0784fa94c">
        {text}
      </a>
    );
  } else if (companyId === CognizantSoftvisionId) {
    return (
      <>
        <a href="https://play.google.com/store/apps/details?id=ro.softvision.quizapp&hl=ro&gl=US&pli=1">{`Android ${text}`}</a>
        <br />
        or
        <br />
        <a href="https://apps.apple.com/ro/app/softvision-tech-quiz/id1439766123?l=ro">{`iOS ${text}`}</a>
      </>
    );
  } else if (companyId === SoftserveId) {
    return <a href="https://forms.office.com/e/uh1RRMZR5e">{text}</a>;
  }
  return <></>;
}

export default function ApplicationInfo() {
  const { t } = useTranslation();
  const tableHeader = t("ApplicationInfo_HeaderTable", {
    returnObjects: true,
  }) as String[];
  const [applications, setApplications] = useState<Application[]>([]);
  const nav = useNavigate();
  const lgBreakpoint = useMediaQuery({ query: "(max-width: 992px)" });

  useQuery("applications", getApplicationsRequest, {
    onSuccess: (data) => {
      setApplications(data);
    },
    onError: (error: any) => {
      console.error(error);
      Swal.fire(parseErrorToSweetAlert(t, error as BeError));
    },
  });

  const [showPartnerDetails, setShowPartnerDetails] = useState(false);
  const [selecterPartner, setSelectedPartner] = useState<Company | undefined>(
    undefined
  );

  return (
    <>
      <ProfilePageContainer>
        <h1 className="mb-4 text-center w-100">{t("ApplicationInfo_Title")}</h1>
        <Table striped bordered hover className="text-center align-middle">
          <thead>
            <tr>
              {tableHeader.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {applications.map((a, index) => (
              <tr key={index}>
                <td>
                  <Image
                    width={lgBreakpoint ? 60 : 100}
                    className="mx-3"
                    src={a.company.logoUrl}
                  />
                  <div>{a.company.name}</div>
                </td>
                <td>
                  <TargButton
                    onClick={() => {
                      setSelectedPartner(a.company);
                      setShowPartnerDetails(true);
                    }}
                    variant="green"
                    className={styles.smTargBtn}
                  >
                    {t("ApplicationInfo_CompanyDetails")}
                  </TargButton>
                </td>
                <td>
                  {a.quizSubmission ? (
                    a.quizSubmission.quizSubmitted ? (
                      <>
                        {a.quizSubmission.score} / {a.company.quizz.maxScore}
                      </>
                    ) : (
                      <TargButton
                        variant="green"
                        className={styles.smTargBtn}
                        onClick={() => nav(`/quiz/${a.company.quizz.id}`)}
                      >
                        {t("ApplicationInfo_QuizContinue")}
                      </TargButton>
                    )
                  ) : a.company.quizz ? (
                    <TargButton
                      variant="green"
                      className={styles.smTargBtn}
                      onClick={async () => {
                        const result = await Swal.fire({
                          title: `${t("ApplicationInfo_SwalTitle")} ${
                            a.company.name
                          }`,
                          text: `${t("ApplicationInfo_SwalText_1")} ${
                            a.company.quizz.time
                          } ${t("ApplicationInfo_SwalText_2")}`,
                          showCancelButton: true,
                          confirmButtonText: t("ApplicationInfo_SwalConfirm"),
                          cancelButtonText: t("ApplicationInfo_SwalCancel"),
                          confirmButtonColor: "#1d911d",
                          cancelButtonColor: "#b4b4b4",
                        });
                        if (result.isConfirmed) {
                          nav(`/quiz/${a.company.quizz.id}`);
                        }
                      }}
                    >
                      {t("ApplicationInfo_QuizStart")}
                    </TargButton>
                  ) : isSnowflake(a.company.id) ? (
                    <RenderSnowflake companyId={a.company.id} />
                  ) : (
                    <>{t("ApplicationInfo_QuizMissing")}</>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ProfilePageContainer>

      <Modal
        size="lg"
        show={showPartnerDetails}
        onHide={() => setShowPartnerDetails(false)}
        scrollable
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selecterPartner?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {parse(
              getLanguagePreference() === "ro"
                ? selecterPartner?.descriptionRo ||
                    selecterPartner?.descriptionEn ||
                    ""
                : selecterPartner?.descriptionEn ||
                    selecterPartner?.descriptionRo ||
                    ""
            )}
          </p>
          <p>
            {t("ApplicationInfo_CompanyWebsiteLnk")}{" "}
            <a href={selecterPartner?.url}>link</a>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}
