import axios from "axios";
import { LangObj } from "../../utils/api_utils";

export interface CreateCompanyAccountDto {
  email: string;
  lastName: string;
  firstName: string;
  password: string;
  password_confirmation: string;
  token: string;
}

interface ForgotPasswordResponse {
  message: LangObj;
}

export async function createCompanyAccount(
  data: CreateCompanyAccountDto
): Promise<ForgotPasswordResponse> {
  let result = await axios.post(
    `${process.env.REACT_APP_BE_URL}/auth/register-company`,
    data
  );
  return result.data as ForgotPasswordResponse;
}
