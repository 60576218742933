import axios from "axios";

export interface RouteActivity {
  route: string;
  hours: number;
}

export interface AddRoutesActivityDto {
  activity: RouteActivity[];
}

export async function addRoutesActivity(
  data: AddRoutesActivityDto
): Promise<void> {
  // daca ceva merge rau va arunca o eroare, altfel este totul bine :)
  await axios.patch(`${process.env.REACT_APP_BE_URL}/route`, data);
}
