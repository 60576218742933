import axios from "axios";
import { LangObj } from "../../utils/api_utils";
import { getAuthHeader } from "../../utils/auth";
import { Year } from "../../utils/general_utils";
import { Quiz, Submission } from "../quiz/api";

interface ApplicantRequestProps {
  code: string;
  note: string;
}

export async function applicantRequest(
  data: ApplicantRequestProps
): Promise<{ message: LangObj }> {
  // daca ceva merge rau va arunca o eroare, altfel este totul bine :) await axios.post(`${process.env.REACT_APP_BE_URL}/contact`, data);
  const result = await axios.post(
    `${process.env.REACT_APP_BE_URL}/profile/applicants`,
    data,
    getAuthHeader()
  );
  return result.data as { message: LangObj };
}

export interface Application {
  id: number;
  seen: boolean;
  note: string;
  participant: {
    id: number;
    email: string;
    lastName: string;
    firstName: string;
    year: Year;
    faculty: string;
    CVId: string;
    originalCVName: string;
    tags: string;
    CVKeywords: string;
  };
  quizSubmission: Submission;
}

export interface ApplicantResponseDataDto {
  applications: Application[];
  quizz: Quiz;
  allCVs: boolean;
}

export interface ApplicantResponseMetadataDto {
  count: number;
  page: number;
  pages: number;
}

export interface ApplicantResponseDto {
  data: ApplicantResponseDataDto;
  meta: ApplicantResponseMetadataDto;
}

export async function getApplicantRequest(
  page: number,
  pageSize: number,
  year?: Year
): Promise<ApplicantResponseDto> {
  // daca ceva merge rau va arunca o eroare, altfel este totul bine :) await axios.post(`${process.env.REACT_APP_BE_URL}/contact`, data);
  let url = `${process.env.REACT_APP_BE_URL}/profile/applicants?page=${page}&take=${pageSize}`;

  const result = await axios.get(url, getAuthHeader());
  return result.data as ApplicantResponseDto;
}

export async function updateNote(id: number, note: string) {
  let url = `${process.env.REACT_APP_BE_URL}/profile/applicants/${id}`;
  const result = await axios.patch(
    url,
    {
      note: note,
    },
    getAuthHeader()
  );

  return result;
}

interface MessageResponse {
  message: LangObj;
}

interface CSVResponse {
  csv: string;
}

export async function getCsvApplicants(): Promise<CSVResponse> {
  // daca ceva merge rau va arunca o eroare, altfel este totul bine :) await axios.post(`${process.env.REACT_APP_BE_URL}/contact`, data);
  const result = await axios.get(
    `${process.env.REACT_APP_BE_URL}/profile/applicants/csv`,
    getAuthHeader()
  );
  return result.data as CSVResponse;
}

export async function getMyCVs(): Promise<MessageResponse> {
  // daca ceva merge rau va arunca o eroare, altfel este totul bine :) await axios.post(`${process.env.REACT_APP_BE_URL}/contact`, data);
  const result = await axios.get(
    `${process.env.REACT_APP_BE_URL}/files/my-cvs`,
    getAuthHeader()
  );
  return result.data as MessageResponse;
}

export async function getAllCVs(): Promise<MessageResponse> {
  // daca ceva merge rau va arunca o eroare, altfel este totul bine :) await axios.post(`${process.env.REACT_APP_BE_URL}/contact`, data);
  const result = await axios.get(
    `${process.env.REACT_APP_BE_URL}/files/all-cvs`,
    getAuthHeader()
  );
  return result.data as MessageResponse;
}

export async function getFilteredCvs(participantIds: number[]): Promise<MessageResponse> {
  // daca ceva merge rau va arunca o eroare, altfel este totul bine :)
  const result = await axios.get(
	`${process.env.REACT_APP_BE_URL}/files/filtered-cvs`,
	{
		...getAuthHeader(),
		params: { participantIds },
	},
  );
  return result.data as MessageResponse;
}