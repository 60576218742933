import i18n from "../i18n/i18n.config";

export type Year =
  | "I"
  | "II"
  | "III"
  | "IV"
  | "MASTER1"
  | "MASTER2"
  | "DOCTORAT";

export const YEARS = {
  I: "I",
  II: "II",
  III: "III",
  IV: "IV",
  MASTER1: "Master anul 1",
  MASTER2: "Master anul 2",
  DOCTORAT: "Doctorat",
  OTHER: "Altceva",
};

export function switchLanguage() {
  const newLang = i18n.language === "en" ? "ro" : "en";
  i18n.changeLanguage(newLang);
}

type LanguagePreference = "en" | "ro";

export function getLanguagePreference(): LanguagePreference {
  return i18n.language as LanguagePreference;
}
