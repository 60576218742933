import axios from "axios";
import fileDownload from "js-file-download";
import { LangObj } from "../../utils/api_utils";
import { getAuthHeader } from "../../utils/auth";
import { Year } from "../../utils/general_utils";

export interface ParticipantAccount {
  id: number;
  role: "USER";

  firstName: string;
  lastName: string;
  email: string;

  CVId?: string;
  originalCVName?: string;
  year: Year;
  faculty: string;

  tags: string;
}

export interface CompanyAccount {
  id: number;
  role: "COMPANY" | "ADMIN";

  firstName: string;
  lastName: string;
  email: string;

  company: { name: string };
}

export async function getProfile(): Promise<
  CompanyAccount | ParticipantAccount
> {
  const result = await axios.get(
    `${process.env.REACT_APP_BE_URL}/profile`,
    getAuthHeader()
  );
  return result.data;
}

interface UpdateProfileProps {
  firstName?: string;
  lastName?: string;
  faculty?: string;
  year?: string;
  cv?: Blob;
}

export async function updateProfile(data: UpdateProfileProps): Promise<any> {
  let form = new FormData();
  for (let [key, value] of Object.entries(data)) {
    console.log(key, value);
    if (value) form.append(key, value);
  }

  const result = await axios.patch(
    `${process.env.REACT_APP_BE_URL}/profile`,
    form,
    getAuthHeader()
  );
  return result.data;
}

export async function deleteProfile(): Promise<any> {
  const result = await axios.delete(
    `${process.env.REACT_APP_BE_URL}/profile`,
    getAuthHeader()
  );

  return result.data;
}

export async function updateCompanyProfile(
  data: UpdateProfileProps
): Promise<any> {
  const body = {
    ...data,
  };

  const result = await axios.patch(
    `${process.env.REACT_APP_BE_URL}/profile/company`,
    body,
    getAuthHeader()
  );

  return result.data;
}

export async function deleteCompanyProfile(): Promise<any> {
  const result = await axios.delete(
    `${process.env.REACT_APP_BE_URL}/profile/company`,
    getAuthHeader()
  );

  return result.data;
}

export async function getCV(
  CVId: string | undefined,
  CVName: string | undefined
): Promise<any> {
  const result = await axios.get(
    `${process.env.REACT_APP_BE_URL}/files/cvs/${CVId}`,
    {
      ...getAuthHeader(),
      responseType: "blob",
    }
  );
  fileDownload(result.data, CVName || "cv.pdf");
}
