import { ErrorMessage, Field } from "formik";
import { Image, Stack } from "react-bootstrap";
import { CreateAnswerDto, Question, StartQuizResponse, Option } from "./api";
import * as Yup from "yup";
import styles from "./QuizForm.module.css";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useTranslation } from "react-i18next";

export interface QuestionProps {
  question: Question;
  index: number;
  values?: any;
}

function checkCorectness(
  selectedValue: string | string[],
  correctOptions: Option[]
): boolean {
  if (typeof selectedValue === "string")
    return Number(selectedValue) === correctOptions[0].id;

  if (selectedValue.length !== correctOptions.length) return false;

  return (
    selectedValue.every((s) =>
      correctOptions.find((o) => o.id === Number(s))
    ) &&
    correctOptions.every((o) => selectedValue.find((s) => Number(s) === o.id))
  );
}

export function QuestionField({ question, index, values }: QuestionProps) {
  const { t } = useTranslation();
  const correctOptions = question.options.filter((o) => o.isCorrect);
  if (correctOptions.length === 0)
    return (
      <>
        <p className={`${styles.questionText} align-left`}>
          <ReactMarkdown children={`${index + 1}\\. ${question.text}`} />
        </p>
        {question.imgUrl && (
          <Image
            src={question.imgUrl}
            className="mx-auto"
            style={{ maxWidth: "75%" }}
          />
        )}
        <QuestionOptions question={question} />
        <ErrorMessage
          name={`${question.id}`}
          component="div"
          className={styles.error}
        />
      </>
    );

  const selectedValues = values[question.id];
  const isCorrect = checkCorectness(selectedValues, correctOptions);

  return (
    <>
      <p className={`${styles.questionText} align-left mb-1`}>
        <ReactMarkdown children={`${index + 1}\\. ${question.text}`} />
      </p>
      <p className={`${styles.questionText} mt-0 align-left`}>
        <b>
          {isCorrect ? (
            <span style={{ color: "#006a03" }}>{t("Common_Correct")}</span>
          ) : (
            <span style={{ color: "red" }}>{t("Common_Wrong")}</span>
          )}
        </b>
      </p>
      {question.imgUrl && (
        <Image
          src={question.imgUrl}
          className="mx-auto"
          style={{ maxWidth: "75%" }}
        />
      )}
      <QuestionOptions question={question} />
      <ErrorMessage
        name={`${question.id}`}
        component="div"
        className={styles.error}
      />
    </>
  );
}

export interface QuestionOptionsProps {
  question: Question;
}

export function QuestionOptions({ question }: QuestionOptionsProps) {
  const { t } = useTranslation();
  switch (question.type) {
    case "SINGLE":
      return (
        <>
          {question.options.map((option) => (
            <label key={option.id} htmlFor={`${option.id}`}>
              <Stack
                direction="horizontal"
                className={`align-items-center ${styles.noMarginBottom}`}
              >
                <Field
                  id={`${option.id}`}
                  type="radio"
                  name={`${question.id}`}
                  value={`${option.id}`}
                  className={styles.options}
                />
                <div>
                  <ReactMarkdown children={option.text} />
                </div>
              </Stack>
            </label>
          ))}
        </>
      );
    case "MULTIPLE":
      return (
        <>
          {question.options.map((option) => (
            <label key={option.id} htmlFor={`${option.id}`}>
              <Stack
                direction="horizontal"
                className={`align-items-center ${styles.noMarginBottom}`}
              >
                <Field
                  id={`${option.id}`}
                  type="checkbox"
                  name={`${question.id}`}
                  value={`${option.id}`}
                  className={styles.options}
                />
                <div>
                  <ReactMarkdown children={option.text} />
                </div>
              </Stack>
            </label>
          ))}
        </>
      );
    case "OPEN":
      return (
        <Field
          as="textarea"
          rows="6"
          name={`${question.id}`}
          placeholder={t("Common_ResponsePlaceholder")}
        />
      );
    default:
      return <p>{t("Common_QuestionError")}</p>;
  }
}

export function generateInitialValues(data: StartQuizResponse) {
  // build the answer object
  const values: { [key: string]: string | string[] } = {};
  for (const question of data.quizz.questions) {
    if (question.type === "SINGLE") {
      values[`${question.id}`] = "";
    } else if (question.type === "MULTIPLE") {
      values[`${question.id}`] = [];
    } else if (question.type === "OPEN") {
      values[`${question.id}`] = "";
    }
  }

  // fill the answers if available
  for (const answer of data.submission.quizAnswers) {
    const question = data.quizz.questions.find(
      (q) => q.id === answer.questionId
    );
    if (
      !question ||
      (answer.text === "" && answer.selectedOptions.length === 0)
    )
      continue;

    // now question must have an answer
    if (question.type === "SINGLE") {
      values[`${question.id}`] = answer.selectedOptions[0].optionId.toString();
    } else if (question.type === "MULTIPLE") {
      values[`${question.id}`] = answer.selectedOptions.map((o) =>
        o.optionId.toString()
      );
    } else if (question.type === "OPEN") {
      values[`${question.id}`] = answer.text;
    }
  }

  return values;
}

export function generateValidationSchema(
  data: StartQuizResponse,
  t: (key: string) => string
) {
  const mandatory = t("Common_MandatoryQuestion");
  const values: any = {};
  for (const question of data.quizz.questions) {
    if (question.type === "SINGLE") {
      values[`${question.id}`] = Yup.string().required(mandatory);
    } else if (question.type === "MULTIPLE") {
      values[`${question.id}`] = Yup.array()
        .min(1, mandatory)
        .required(mandatory);
    } else if (question.type === "OPEN") {
      values[`${question.id}`] = Yup.string().required(mandatory);
    }
  }
  return values;
}

export function generateAnswerDtoFromValues(
  values: any,
  data: StartQuizResponse
) {
  return Object.keys(values).map((key) => {
    switch (data.quizz.questions.find((q) => q.id === Number(key))?.type) {
      case "MULTIPLE":
        return {
          selectedOptions: (values[key] as string[]).map((o) => Number(o)),
          questionId: Number(key),
          text: "",
        } as CreateAnswerDto;
      case "SINGLE":
        let selectedOptions: number[] = [];
        if (values[key] !== "") {
          selectedOptions = [Number(values[key])];
        }
        return {
          selectedOptions,
          text: "",
          questionId: Number(key),
        } as CreateAnswerDto;
      case "OPEN":
        return {
          selectedOptions: [],
          text: values[key],
          questionId: Number(key),
        } as CreateAnswerDto;
      default:
        return {} as CreateAnswerDto;
    }
  });
}
