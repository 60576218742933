import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import { Form, Button, Stack, Row, Col, Spinner } from "react-bootstrap";
import { SelectInput, TextInput, ToggleInput } from "../../common/Inputs";
import * as Yup from "yup";
import { registerParticipant } from "./api";
import { useNavigate } from "react-router-dom";
import { BeError, parseErrorToSweetAlert } from "../../utils/api_utils";
import Swal from "sweetalert2";
import {
  eMin,
  eRequired,
  eEmail,
  eMax,
  ePassConfirmation,
  eSelectMin,
  eFirstName,
  eLastName,
  eFacultyName,
} from "../../utils/validation_messages";
import styles from "./AuthForm.module.css";
import inputStyles from "../Inputs.module.css";
import { useTranslation } from "react-i18next";
import { getLanguagePreference } from "../../utils/general_utils";
import { tags } from "../../utils/constants";
import { useMediaQuery } from "react-responsive";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function RegisterForm() {
  const { t } = useTranslation();
  let nav = useNavigate();
  const mdBreakpoint = useMediaQuery({ query: "(min-width: 768px)" });
  const faculties = t("RegisterForm_Faculties", {
    returnObjects: true,
  }) as string[];

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        facultyDropDown: -1,
        faculty: "",
        year: "",
        password: "",
        password_confirmation: "",
        gdpr: false,
        tags: Array(tags.length).fill(""),
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .matches(
            /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,2}[.]{0,1}$/,
            eFirstName(t)
          )
          .max(100, eMax(t, t("RegisterForm_EmailReq"), 100))
          .required(eRequired(t, t("RegisterForm_EmailReq"))),
        lastName: Yup.string()
          .matches(
            /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,2}[.]{0,1}$/,
            eLastName(t)
          )
          .max(100, eMax(t, t("RegisterForm_LastNameReq"), 100))
          .required(eRequired(t, t("RegisterForm_LastNameReq"))),
        email: Yup.string()
          .email(eEmail(t))
          .max(100, eMax(t, t("RegisterForm_EmailReq"), 100))
          .required(eRequired(t, t("RegisterForm_EmailReq"))),
        facultyDropDown: Yup.number()
          .oneOf(
            faculties.map((_, i) => i),
            t("RegisterForm_FacultyValid")
          )
          .required(eRequired(t, t("RegisterForm_FacultyReq"))),
        faculty: Yup.string().when(["facultyDropDown"], (facultyDropDown) => {
          if (Number(facultyDropDown) === faculties.length - 1) {
            return Yup.string()
              .matches(
                /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,7}[.]{0,1}$/,
                eFacultyName(t)
              )
              .max(100)
              .required(eRequired(t, t("RegisterForm_FacultyReq")));
          }

          return Yup.string().optional();
        }),
        year: Yup.string()
          .oneOf([
            "I",
            "II",
            "III",
            "IV",
            "MASTER1",
            "MASTER2",
            "DOCTORAT",
            "OTHER",
          ])
          .required(eRequired(t, t("RegisterForm_Year"))),
        password: Yup.string()
          .min(8, eMin(t, t("RegisterForm_PasswordReq"), 8))
          .max(100)
          .required(eRequired(t, t("RegisterForm_PasswordReq"), true)),
        password_confirmation: Yup.string()
          .oneOf([Yup.ref("password")], ePassConfirmation(t))
          .required(
            eRequired(t, t("RegisterForm_PasswordConfirmationReq"), true)
          ),
        gdpr: Yup.boolean().isTrue(t("RegisterForm_TermsReq")).required(),
        tags: Yup.array().test(
          "tags",
          eSelectMin(t, t("RegisterForm_TagsSelect"), 3),
          (toggledTags) =>
            toggledTags !== undefined &&
            toggledTags.filter((tag) => tag && tags.includes(tag)).length >= 3
        ),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          if (Number(values.facultyDropDown) !== faculties.length - 1) {
            values.faculty = faculties[values.facultyDropDown];
          }

          const data = {
            ...values,
            tags: values.tags.filter((tag) => tag !== "").join(";"),
          };
          const res = await registerParticipant(data);
          Swal.fire({
            icon: "success",
            text: res.message[getLanguagePreference()],
          });
          nav("/register");
          await sleep(100);
          nav("/login");
        } catch (error: any) {
          console.error(error);
          Swal.fire(parseErrorToSweetAlert(t, error as BeError));
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, handleSubmit, values }) => (
        <Form className={styles.form} onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <TextInput
                label={t("RegisterForm_LastName")}
                name="lastName"
                type="text"
                placeholder="Popescu"
                popupForm
                padding
              />

              <TextInput
                label={t("RegisterForm_FirstName")}
                name="firstName"
                type="text"
                placeholder="Ioan"
                popupForm
                padding
              />

              <SelectInput
                label={t("RegisterForm_Faculty")}
                name="facultyDropDown"
                popupForm
                padding
              >
                <option value={-1}>{t("RegisterForm_FacultyChoose")}</option>
                {faculties.map((faculty, index) => (
                  <option key={index} value={index}>
                    {faculty}
                  </option>
                ))}
              </SelectInput>
              {Number(values.facultyDropDown) === faculties.length - 1 ? (
                <TextInput
                  label={t("RegisterForm_Faculty")}
                  name="faculty"
                  type="text"
                  placeholder={faculties[0]}
                  popupForm
                  padding
                />
              ) : (
                <></>
              )}

              <SelectInput
                label={t("RegisterForm_Year")}
                name="year"
                popupForm
                padding
              >
                <option value="">{t("RegisterForm_YearChoose")}</option>
                <option value="I">{t("RegisterForm_YearB1")}</option>
                <option value="II">{t("RegisterForm_YearB2")}</option>
                <option value="III">{t("RegisterForm_YearB3")}</option>
                <option value="IV">{t("RegisterForm_YearB4")}</option>
                <option value="MASTER1">{t("RegisterForm_YearM1")}</option>
                <option value="MASTER2">{t("RegisterForm_YearM2")}</option>
                <option value="DOCTORAT">{t("RegisterForm_YearP")}</option>
                <option value="OTHER">{t("RegisterForm_Other")}</option>
              </SelectInput>

              <FieldArray
                name="tags"
                render={(arrayHelpers) => (
                  <>
                    {tags.map((tag, index) => (
                      <ToggleInput
                        key={index}
                        className={`m-1 px-2 py-1 ${styles.tag} ${
                          mdBreakpoint ? styles.tagBig : styles.tag
                        }`}
                        label={tag}
                        isToggled={values.tags[index] !== ""}
                        swapColors
                        onClick={() => {
                          if (values.tags[index] === "")
                            arrayHelpers.replace(index, tag);
                          else arrayHelpers.replace(index, "");
                        }}
                      />
                    ))}
                    <ErrorMessage
                      component="div"
                      className={`${inputStyles.error} text-center`}
                      name="tags"
                    />
                  </>
                )}
              />
            </Col>

            <Col md={6}>
              <TextInput
                label={t("RegisterForm_Email")}
                name="email"
                type="email"
                placeholder="andrei@gmail.com"
                popupForm
                padding
              />

              <TextInput
                label={t("RegisterForm_Password")}
                name="password"
                type="password"
                placeholder=""
                popupForm
                padding
              />

              <TextInput
                label={t("RegisterForm_PasswordConfirmation")}
                name="password_confirmation"
                type="password"
                placeholder=""
                popupForm
                padding
              />

              <Form.Group
                className={`${inputStyles.inputGroup} ${inputStyles.popupForm} ${inputStyles.checkboxGroup}`}
                controlId="name"
              >
                <Stack direction="horizontal">
                  <Field type="checkbox" name="gdpr" />
                  <label>
                    {t("RegisterForm_TermsText")}{" "}
                    <a href="/gdpr.docx">{t("RegisterForm_Terms")}</a>
                  </label>
                </Stack>
                <ErrorMessage
                  component="div"
                  className={inputStyles.error}
                  name="gdpr"
                />
              </Form.Group>
            </Col>

            <Stack className="mt-2">
              {!isSubmitting ? (
                <Button className={styles.btn} type="submit">
                  {t("RegisterForm_Register")}
                </Button>
              ) : (
                <Spinner className="mx-auto my-4" />
              )}
            </Stack>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
