import { Formik, FormikProps, FormikValues } from "formik";
import { useEffect, useRef, useState } from "react";
import { Form, Spinner, Stack } from "react-bootstrap";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import ProfilePageContainer from "../../common/ProfilePageContainer";
import Sidebar from "../../common/Sidebar";
import TargButton from "../../common/TargButton";
import { BeError, parseErrorToSweetAlert } from "../../utils/api_utils";
import { StartQuizResponse, startQuiz, submitQuiz } from "./api";
import {
  generateAnswerDtoFromValues,
  generateInitialValues,
  generateValidationSchema,
  QuestionField,
} from "./common";
import styles from "./QuizForm.module.css";
import { useTranslation } from "react-i18next";

export default function QuizPage() {
  const { t } = useTranslation();
  let { id } = useParams();
  const lgBreakpoint = useMediaQuery({ query: "(max-width: 992px)" });
  const nav = useNavigate();
  // initial value must be greater than 1
  const [remainingSeconds, setRemainingSeconds] = useState(0);

  const { error, data } = useQuery<StartQuizResponse, BeError>({
    queryKey: ["startQuiz", Number(id)],
    queryFn: async () => {
      return await startQuiz(Number(id));
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  if (error) {
    console.error(error);
    Swal.fire(parseErrorToSweetAlert(t, error as BeError));
    nav("/profile/applications");
  }

  useEffect(() => {
    function handler(ev: any) {
      ev.preventDefault();
      return (ev.returnValue = "Are you sure you want to close?");
    }
    window.addEventListener("beforeunload", handler);

    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, []);

  const formRef = useRef<FormikProps<FormikValues>>(null);

  useEffect(() => {
    const autoSubmitInterval = setInterval(async () => {
      if (!data) return;
      const startTime = Date.parse(data.submission.createdAt);
      const now = Date.now();
      const elapsedTimeSeconds = (now - startTime) / 1000;
      const remSeconds = Math.floor(data.quizz.time * 60 - elapsedTimeSeconds);
      setRemainingSeconds(remSeconds);
      if (remSeconds > 0) return;

      submitQuiz(Number(id), {
        answers: generateAnswerDtoFromValues(formRef.current?.values, data),
        final: true,
      });
      Swal.fire({
        icon: "info",
        html: t("QuizPage_TimeExpired"),
      });
      nav("/profile/applications");
    }, 500);
    return () => {
      clearInterval(autoSubmitInterval);
    };
  }, [data, remainingSeconds]);

  useEffect(() => {
    const saveInterval = setInterval(async () => {
      if (!data) return;

      await submitQuiz(Number(id), {
        answers: generateAnswerDtoFromValues(formRef.current?.values, data),
        final: false,
      });
    }, 1000 * 60);

    return () => {
      clearInterval(saveInterval);
    };
  }, [data]);

  return (
    <Stack direction={lgBreakpoint ? "vertical" : "horizontal"}>
      <Sidebar />
      <ProfilePageContainer>
        {data ? (
          <>
            <h1 className="m-5 text-center">
              {`${t("QuizPage_SwalTitle")} ${data.quizz.company.name}`}
            </h1>
            <h1 className="text-center w-100" style={{ color: "#006A03" }}>
              {remainingSeconds > 0 && (
                <>
                  {`00${Math.floor(remainingSeconds / 60)}`.slice(-2)}:
                  {`00${Math.floor(remainingSeconds % 60)}`.slice(-2)}
                </>
              )}
            </h1>
            <Formik
              innerRef={formRef}
              initialValues={generateInitialValues(data)}
              validationSchema={Yup.object(generateValidationSchema(data, t))}
              enableReinitialize
              onSubmit={async (
                values: { [key: string]: string[] | string },
                { setSubmitting }
              ) => {
                Swal.fire({
                  title: `${t("QuizPage_SwalTitle")} ${
                    data.quizz.company.name
                  }`,
                  text: t("QuizPage_SwalText"),
                  showCancelButton: true,
                  confirmButtonText: t("QuizPage_SwalConfirm"),
                  cancelButtonText: t("QuizPage_SwalCancel"),
                  confirmButtonColor: "#1d911d",
                  cancelButtonColor: "#b4b4b4",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    try {
                      await submitQuiz(Number(id), {
                        answers: generateAnswerDtoFromValues(values, data),
                        final: true,
                      });
                      nav("/profile/applications");
                    } catch (error: any) {
                      console.error(error);
                      Swal.fire(parseErrorToSweetAlert(t, error as BeError));
                    }
                  }
                });
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, handleSubmit, isValid }) => (
                <Form className={styles.form} onSubmit={handleSubmit}>
                  <Stack gap={3} className="pl-3">
                    {data.quizz.questions.map((q, index) => (
                      <QuestionField key={q.id} question={q} index={index} />
                    ))}
                    {!isSubmitting ? (
                      <>
                        {!isValid && (
                          <Stack
                            className="text-center w-100"
                            style={{ color: "rgba(0,0,0,0.7)" }}
                          >
                            <>{t("QuizPage_UnansweredQuestions")}</>
                          </Stack>
                        )}
                        <TargButton
                          className={styles.smTargBtn}
                          type="submit"
                          variant={"green"}
                        >
                          {t("QuizPage_Submit")}
                        </TargButton>
                      </>
                    ) : (
                      <Spinner className="mx-auto my-4" />
                    )}
                  </Stack>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <Spinner className="mx-auto my-4" />
        )}
      </ProfilePageContainer>
    </Stack>
  );
}
