import { SweetAlertOptions } from "sweetalert2";
import { logout } from "./auth";
import { getLanguagePreference } from "./general_utils";

export interface LangObj {
  ro: string;
  en: string;
}

export interface BeError {
  code: string;
  response: {
    status: number;
    data: {
      message: string[] | string | LangObj;
    };
  };
}

function extractMessageToHtml(err: BeError) {
  const msg: any = err.response.data.message;
  if (typeof msg === "string") return msg;
  else if (Array.isArray(msg) === false) return msg[getLanguagePreference()];
  else return msg.reduce((acc: any, curr: any) => `${acc}<p>${curr}</p>`);
}

export function parseErrorToSweetAlert(
  t: (key: string) => string,
  err: BeError
): SweetAlertOptions {
  if (err.code === "ERR_NETWORK")
    return {
      icon: "error",
      title: t("ApiUtils_errNet"),
    };
  else if (err.response?.status === 400)
    return {
      icon: "error",
      html: extractMessageToHtml(err),
    };
  else if (err.response?.status === 401) {
    logout();
    window.location.reload();
    return {
      icon: "error",
      title: t("ApiUtils_auth401"),
    };
  } else if (err.response?.status === 403)
    return {
      icon: "error",
      html: extractMessageToHtml(err),
    };
  else if (err.response?.status === 404)
    return {
      icon: "error",
      title: t("ApiUtils_e404"),
    };
  else if (err.response?.status === 409)
    return {
      icon: "error",
      title: "Conflict",
      html: extractMessageToHtml(err),
    };
  else if (err.response?.status === 413)
	return {
      icon: "error",
	  title: "Payload too large (1MB max)",
	}
  else
    return {
      icon: "error",
      title: t("ApiUtils_e500"),
    };
}
