import { Stack } from "react-bootstrap";
import Sidebar from "../../common/Sidebar";
import Info from "./Info";
import { useMediaQuery } from "react-responsive";

// https://xd.adobe.com/view/56109ec9-b893-49f8-9590-cb7f686404f5-0343/screen/a5c5f96f-bb0f-49e2-9510-986a7ccf91f6
export default function Profile() {
  const lgBreakpoint = useMediaQuery({ query: "(max-width: 992px)" });

  return (
    <Stack direction={lgBreakpoint ? "vertical" : "horizontal"}>
      <Sidebar />
      <Info />
    </Stack>
  );
}
