import { Stack } from "react-bootstrap";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { useTranslation } from "react-i18next";

export default function ForgotPassword() {
  const { t } = useTranslation();

  return (
    <Stack>
      <Header />
      <Stack className="justify-content-center align-items-center">
        <h2 className="m-5">{t("ForgotPassword_Title")}</h2>
        <ForgotPasswordForm />
      </Stack>
      <Footer />
    </Stack>
  );
}
