import { Stack } from "react-bootstrap";
import Sidebar from "../../common/Sidebar";
import ProfilePageContainer from "../../common/ProfilePageContainer";
import { getApplicationCode } from "../../utils/auth";
import { QRCodeSVG } from "qrcode.react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

export default function Code() {
  const { t } = useTranslation();
  const code = getApplicationCode();
  const lgBreakpoint = useMediaQuery({ query: "(max-width: 992px)" });

  return (
    <Stack direction={lgBreakpoint ? "vertical" : "horizontal"}>
      <Sidebar />
      <ProfilePageContainer>
        <Stack className="text-center mb-4">
          <h1 className="mb-4">{t("Code_Title")}</h1>
          <h2 className="mb-4">
            {t("Code_Text")}
            <br />
            <b>{code}</b>
          </h2>
          <QRCodeSVG
            width={200}
            height={200}
            className="mx-auto"
            value={code || "999999"}
          />
        </Stack>
      </ProfilePageContainer>
    </Stack>
  );
}
