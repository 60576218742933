import { Col, Container, Row, Stack } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import styles from "./Organizers.module.css";
import styles2 from "./HowTo.module.css";
import { useTranslation } from "react-i18next";

// https://www.figma.com/file/SWHby6nl2RlaGnOwxRJSDd/Site?node-id=4%3A1539&t=4z9Ab57qIZ5FP8YH-0
export default function Organizers() {
  const { t } = useTranslation();
  const mainOrganizers = [
    {
      imgUrl: "bibi.jpg",
      name: "BIANCA SCÎRTOCEA",
      title: "Fundraising Manager",
      phone: "+40 727 684 594",
      email: "bianca.scirtocea@lsacbucuresti.ro",
    },
    {
      imgUrl: "diana.jpg",
      name: "DIANA PINȚOIU",
      title: "Project Manager",
      phone: "+40 725 217 827",
      email: "diana.pintoiu@lsacbucuresti.ro",
    },
    {
      imgUrl: "alexandra.jpg",
      name: "ALEXANDRA NIOAȚĂ",
      title: "Public Relations Manager",
      phone: "+40 720 372 087",
      email: "alexandra.nioata@lsacbucuresti.ro",
    },
  ];

  const teamOrganizers = [
    {
      imgUrl: "banca.jpg",
      name: "TUDOR DIACONU",
      title: "Vicepresident",
    },
    {
      imgUrl: "elisa.jpg",
      name: "ELISA DIACONIȚA",
      title: "Design Manager",
    },
    {
      imgUrl: "petrut.jpg",
      name: "PETRUȚ DUINEA",
      title: "Human Resources Manager",
    },
    {
      imgUrl: "alex.jpg",
      name: "ALEX MÎRZEA",
      title: "IT Manager",
    },
    {
      imgUrl: "david.jpg",
      name: "DAVID GRAPA",
      title: "Logistics Manager",
    },
    {
      imgUrl: "laris.jpg",
      name: "LARISA FLOREA",
      title: "Multimedia Manager",
    },
    {
      imgUrl: "simo.jpg",
      name: "SIMONA ALEXE",
      title: "Mentor",
    },
    {
      imgUrl: "andrei.jpg",
      name: "ANDREI NĂPRUIU",
      title: "Mentor",
    },
    {
      imgUrl: "rouge.jpg",
      name: "ANDREI ROŞU",
      title: "Mentor",
    },
    {
      imgUrl: "geo.jpg",
      name: "GEORGIANA GRIGOREANU",
      title: "Mentor",
    },
    {
      imgUrl: "sanda.jpg",
      name: "SANDA NECULAU",
      title: "Mentor",
    },
  ];

  return (
    <>
      <span id="organizers" className="section"></span>
      <Container className="text-center">
        <h1 className="my-4">{t("Organizers_Title")}</h1>
        <Row>
          {mainOrganizers.map((o, index) => (
            <Col
              key={index}
              xs={12}
              lg={4}
              className="d-flex justify-content-center my-4"
            >
              <Stack className={styles.card} gap={0}>
                <img src={o.imgUrl} alt="" />
                <h2>{o.name}</h2>
                <h3>{o.title}</h3>
                <p>{o.phone}</p>
                <p>{o.email}</p>
              </Stack>
            </Col>
          ))}
        </Row>

        <Swiper
          className={styles2.swiper}
          slidesPerView={1}
          centeredSlides
          modules={[Pagination, Navigation, Autoplay]}
          pagination
          navigation
          loop
          autoplay={{
            delay: 6000,
          }}
        >
          {teamOrganizers.map((o, index) => (
            <SwiperSlide key={index} className={`${styles.parentCard}`}>
              <Stack className={`${styles.newCard}`} gap={0}>
                <img src={o.imgUrl} alt="" />
                <h2>{o.name}</h2>
                <h3>{o.title}</h3>
              </Stack>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </>
  );
}
