import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { addRoutesActivity, RouteActivity } from "./api";

export default function TimeTracker() {
  const clockRef = useRef<NodeJS.Timer | null>(null);
  const location = useLocation();
  const secondsInterval = 3;
  const acceptedRoutes = [
    "/",
    "/login",
    "/register",
    "/sanity-check",
    "/confirmation",
    "/forgot-password",
    "/reset-password",
    "/register-company",
    "/profile",
    "/quiz",
    "/profile/scan",
    "/profile/code",
    "/profile/applications",
    "/profile/applicants",
    "/profile/companies-management",
  ];
  const tokenRoutes = [
    "/confirmation",
    "/reset-password",
    "/register-company",
    "/quiz",
  ];

  function startClock() {
    if (!clockRef.current)
      clockRef.current = setInterval(
        storeRouteActivity,
        secondsInterval * 1000
      );
  }
  function stopClock() {
    if (clockRef.current) {
      clearInterval(clockRef.current);
      clockRef.current = null;
    }
  }

  function toHours(seconds: number) {
    return seconds / 3600;
  }

  function removeToken(route: string) {
    const tokenRoute = tokenRoutes.find((tokenRoute) =>
      route.includes(tokenRoute)
    );
    return tokenRoute ? tokenRoute : route;
  }

  function storeRouteActivity() {
    const route = removeToken(location.pathname);
    const routeActivity = localStorage.getItem(route);

    if (!acceptedRoutes.includes(route)) {
      console.error(`Route(${route}) is not accepted!`);
      return;
    }

    const seconds = routeActivity
      ? parseInt(routeActivity, 10) + secondsInterval
      : secondsInterval;
    localStorage.setItem(route, seconds.toString());
  }

  // https://www.igvita.com/2015/11/20/dont-lose-user-and-app-state-use-page-visibility/
  async function handleVisibilityChange() {
    // fires when user switches tabs, apps, goes to homescreen, etc.
    if (document.visibilityState === "hidden") stopClock();

    // fires when app transitions from prerender, user returns to the app / tab.
    if (document.visibilityState === "visible") {
      startClock();

      // send activity to backend
      const activities = acceptedRoutes
        .map((route) => {
          const seconds = localStorage.getItem(route);
          if (seconds !== null) {
            localStorage.removeItem(route);
            return {
              route,
              hours: toHours(parseInt(seconds, 10)),
            };
          }
          return null;
        })
        .filter((activity): activity is RouteActivity => activity !== null);

      try {
        await addRoutesActivity({ activity: activities });
      } catch (error: any) {
        console.error(error);
      }
    }
  }

  // https://react.dev/reference/react/useEffect#connecting-to-an-external-system
  useEffect(() => {
    // Monitor page entering and browser states
    document.addEventListener("visibilitychange", handleVisibilityChange);
    startClock();

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      stopClock();
    };
  }, [location.pathname]);

  return null;
}
