import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";
import Quiz from "./pages/quiz/QuizPage";
import UserApplications from "./pages/user_applications/UserApplications";
import CompanyApplicants from "./pages/company_applicants/CompanyApplicants";
import ForgotPassword from "./pages/forgot_password/ForgotPassword";
import ResetPassword from "./pages/reset_password/ResetPassword";
import CompaniesManagement from "./pages/companies_management/CompaniesManagement";
import { checkRole, Role } from "./utils/auth";
import RegisterCompany from "./pages/register-company/RegisterCompany";
import "bootstrap/dist/css/bootstrap.min.css";
import SanityCheck from "./pages/sanity-check/SanityCheck";
import ConfirmationAccount from "./pages/confirmation_account/ConfirmationAccount";
import "swiper/css/bundle";
import { QueryClient, QueryClientProvider } from "react-query";
import Scan from "./pages/scan/scan";
import Code from "./pages/code/code";
import { Suspense } from "react";
import TimeTracker from "./pages/TimeTracker/TimeTracker";

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={null}>
          <BrowserRouter>
            <TimeTracker />{" "}
            {/* for added/removed route, update TimeTracker accepted and token routes */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/:auth" element={<Home />} />
              <Route path="/sanity-check" element={<SanityCheck />} />
              <Route
                path="confirmation/:token"
                element={<ConfirmationAccount />}
              />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route
                path="/register-company/:token"
                element={<RegisterCompany />}
              />

              <Route path="/profile" element={<Profile />} />
              <Route
                path="/quiz/:id"
                element={
                  checkRole(Role.USER) ? <Quiz /> : <Navigate to="/profile" />
                }
              />

              <Route
                path="/profile/scan"
                element={
                  checkRole(Role.COMPANY) ? (
                    <Scan />
                  ) : (
                    <Navigate to="/profile" />
                  )
                }
              />
              <Route
                path="/profile/code"
                element={
                  checkRole(Role.USER) ? <Code /> : <Navigate to="/profile" />
                }
              />
              <Route
                path="/profile/applications"
                element={
                  checkRole(Role.USER) ? (
                    <UserApplications />
                  ) : (
                    <Navigate to="/profile" />
                  )
                }
              />
              <Route
                path="/profile/applicants"
                element={
                  checkRole(Role.COMPANY) ? (
                    <CompanyApplicants />
                  ) : (
                    <Navigate to="/profile" />
                  )
                }
              />
              <Route
                path="/profile/companies-management"
                element={
                  checkRole(Role.ADMIN) ? (
                    <CompaniesManagement />
                  ) : (
                    <Navigate to="/profile" />
                  )
                }
              />

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </QueryClientProvider>
    </>
  );
}

export default App;
