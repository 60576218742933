import axios from "axios";
import { LangObj } from "../../utils/api_utils";

interface ResetPasswordProps {
  password: string;
  password_confirmation: string;
  token: string;
}

interface ForgotPasswordResponse {
  message: LangObj;
}

export async function resetPassword(
  data: ResetPasswordProps
): Promise<ForgotPasswordResponse> {
  let result = await axios.post(
    `${process.env.REACT_APP_BE_URL}/auth/reset-password`,
    data
  );
  return result.data as ForgotPasswordResponse;
}
