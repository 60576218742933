import { Container, Modal, Tabs, Tab, Button } from "react-bootstrap";
import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";
import styles from "./Auth.module.css";
import stylesForm from "./AuthForm.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface AuthModalProps {
  show: boolean;
  onHide: () => void;
  selected: "register" | "login";
}

export default function AuthModal({ show, selected, onHide }: AuthModalProps) {
  const { t } = useTranslation();
  const nav = useNavigate();
  const closeModal = () => {
    onHide();
    nav("/");
  };

  return (
    <>
      <Modal
        scrollable
        className={styles.transparrentModal}
        fullscreen
        show={show}
        onHide={closeModal}
      >
        <Modal.Body>
          <Container
            className={`${styles.formContainer} min-vh-100 d-flex flex-column justify-content-center align-items-center`}
            fluid
          >
            <Tabs
              className={`${styles.tabs} w-100 d-flex justify-content-center border-0`}
              defaultActiveKey={selected}
            >
              <Tab eventKey="register" title={t("AuthModal_Register")}>
                <RegisterForm />
              </Tab>

              <Tab eventKey="login" title={t("AuthModal_Login")}>
                <LoginForm />
              </Tab>
            </Tabs>
            <Button
              className={`${stylesForm.btn} ${stylesForm.btn2} my-4`}
              onClick={closeModal}
            >
              {t("AuthModal_Back")}
            </Button>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
