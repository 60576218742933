import { Stack } from "react-bootstrap";
import Sidebar from "../../common/Sidebar";
import ApplicationInfo from "./ApplicationInfo";
import { useMediaQuery } from "react-responsive";

export default function UserApplications() {
  const lgBreakpoint = useMediaQuery({ query: "(max-width: 992px)" });

  return (
    <Stack direction={lgBreakpoint ? "vertical" : "horizontal"}>
      <Sidebar />
      <ApplicationInfo />
    </Stack>
  );
}
