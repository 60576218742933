import { Stack } from "react-bootstrap";
import Sidebar from "../../common/Sidebar";
import Companies from "./Companies";
import CreateCompanyForm from "./CreateCompanyForm";

export default function CompaniesManagement() {
  return (
    <Stack direction="horizontal">
      <Sidebar />
      <h1>Companii</h1>
      <CreateCompanyForm />
      <Companies />
    </Stack>
  );
}
