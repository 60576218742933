import { Stack } from "react-bootstrap";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Contact from "./Contact";
import Hero from "./Hero";
import HowTo from "./HowTo";
import Organizers from "./Organizers";
import Partners from "./Partners";
import Statistics from "./Statistics";

import styles from "./Home.module.css";

export default function Home() {
  return (
    <Stack>
      <Header />
      <div className={styles.section}>
        <Hero />
      </div>
      <div className={styles.section}>
        <Statistics />
      </div>
      <div className={styles.section}>
        <HowTo />
      </div>
      <div className={styles.section}>
        <Partners />
      </div>
      <div className={styles.section}>
        <Contact />
      </div>
      <div className={styles.section}>
        <Organizers />
      </div>
      <Footer />
    </Stack>
  );
}
