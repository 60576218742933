import { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, Image } from "react-bootstrap";
import { isLoggedIn, logout } from "../utils/auth";
import { useNavigate, useParams } from "react-router-dom";
import AuthModal from "./auth/AuthModal";
import styles from "./Header.module.css";
import { ReactComponent as LangIcon } from "./assets/lang.svg";
import { useTranslation } from "react-i18next";
import { switchLanguage } from "../utils/general_utils";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Navbarul de aici: https://www.figma.com/file/SWHby6nl2RlaGnOwxRJSDd/Site?node-id=4%3A203&t=p7XhMdgcGVD24ZCi-0
export default function Header() {
  const { t, i18n } = useTranslation();
  const nav = useNavigate();
  const [showAuth, setShowAuth] = useState(false);
  const [authSelected, setAuthSelected] = useState<"register" | "login">(
    "register"
  );
  const [expanded, setExpanded] = useState(false);

  const { auth } = useParams();
  useEffect(() => {
    if (auth === "register") {
      setAuthSelected("register");
      setShowAuth(true);
    } else if (auth === "login") {
      setAuthSelected("login");
      setShowAuth(true);
    }
  }, [auth]);

  async function showLogin() {
    nav("/");
    await sleep(50);
    nav("/login");
  }

  async function showRegister() {
    nav("/");
    await sleep(50);
    nav("/register");
  }

  function handleLogout() {
    logout();
    nav("/");
  }

  function switchState() {
    if (!expanded) setExpanded(true);
    else setExpanded(false);
  }

  return (
    <>
      <Navbar
        className={`${styles.nav} shadow`}
        bg="white"
        expand="xxl"
        sticky="top"
        expanded={expanded}
      >
        <Container className="px-4" fluid>
          <Navbar.Brand href="/">
            <Image className="p-1" height={60} src="/logo.svg" />
          </Navbar.Brand>
          <Navbar.Toggle className="p-1" onClick={switchState} />

          <Navbar.Collapse className="justify-content-end">
            <Nav
              className="align-items-center text-center"
              onSelect={switchState}
            >
              <Nav.Link className="mx-2" href="/#home">
                {t("Header_Home")}
              </Nav.Link>
              <Nav.Link className="mx-2" href="/#howto">
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("Header_ParticipantGuide"),
                  }}
                />
              </Nav.Link>
              <Nav.Link className="mx-2" href="/#premium">
                {t("Header_PremiumPartners")}
              </Nav.Link>
              <Nav.Link className="mx-2" href="/#partners">
                {t("Header_Companies")}
              </Nav.Link>
              <Nav.Link className="mx-2" href="/#contact">
                <div
                  dangerouslySetInnerHTML={{ __html: t("Header_Contact") }}
                />
              </Nav.Link>
              <Nav.Link className="mx-2" href="/#organizers">
                {t("Header_Organizers")}
              </Nav.Link>
              {isLoggedIn() && (
                <Nav.Link className="m-2" href="/profile">
                  {t("Header_MyAccount")}
                </Nav.Link>
              )}
              {!isLoggedIn() && (
                <Button className="m-2" onClick={showRegister}>
                  {t("Header_Register")}
                </Button>
              )}
              {!isLoggedIn() && (
                <Button className="m-2" onClick={showLogin}>
                  {t("Header_Login")}
                </Button>
              )}
              {isLoggedIn() && (
                <Button className="m-2" onClick={handleLogout}>
                  {t("Header_Logout")}
                </Button>
              )}

              <Nav.Link className="mx-2" onClick={switchLanguage}>
                <div style={{ fill: "rgba(0,0,0,0.7)" }}>
                  <LangIcon width={26} />
                  <span className="mx-2">{i18n.language.toUpperCase()}</span>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <AuthModal
        show={showAuth}
        onHide={() => setShowAuth(false)}
        selected={authSelected}
      />
    </>
  );
}
