import { Stack } from "react-bootstrap";
import Sidebar from "../../common/Sidebar";
import Applicants from "./Applicants";
import { useMediaQuery } from "react-responsive";

export default function CompanyApplicants() {
  const lgBreakpoint = useMediaQuery({ query: "(max-width: 992px)" });

  return (
    <Stack direction={lgBreakpoint ? "vertical" : "horizontal"}>
      <Sidebar />
      <Applicants />
    </Stack>
  );
}
