import { Container, Stack } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import styles from "./HowTo.module.css";
import { useTranslation } from "react-i18next";

// https://www.figma.com/file/SWHby6nl2RlaGnOwxRJSDd/Site?node-id=4%3A1039&t=4z9Ab57qIZ5FP8YH-0
export default function HowTo() {
  const { t } = useTranslation();
  const slides = [
    {
      src: "/howto1.svg",
      title: t("HowTo_SlidesCreateAccountTitle"),
      body: t("HowTo_SlidesCreateAccountText"),
    },
    {
      src: "/howto2.svg",
      title: t("HowTo_SlidesUploadCVTitle"),
      body: t("HowTo_SlidesUploadCVText"),
    },
    {
      src: "/howto3.svg",
      title: t("HowTo_SlidesComeToEventTitle"),
      body: t("HowTo_SlidesComeToEventText"),
    },
    {
      src: "/howto5.svg",
      title: t("HowTo_SlidesApplyToCompaniesTitle"),
      body: t("HowTo_SlidesApplyToCompaniesText"),
    },
    {
      src: "/howto6.svg",
      title: (
        <div dangerouslySetInnerHTML={{ __html: t("HowTo_SlidesQuizTitle") }} />
      ),
      body: t("HowTo_SlidesQuizText"),
    },
  ];

  return (
    <>
      <span id="howto" className="section"></span>
      <Container className="text-center">
        <h1 className={styles.title}>{t("HowTo_Title")}</h1>

        <Swiper
          className={styles.swiper}
          slidesPerView={1}
          centeredSlides
          modules={[Pagination, Navigation]}
          pagination
          navigation
          loop
        >
          {slides.map((s, index) => (
            <SwiperSlide key={index}>
              <Stack className="align-items-center">
                <img src={s.src} alt="" />
                <h2>{s.title}</h2>
                <p>{s.body}</p>
              </Stack>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </>
  );
}
