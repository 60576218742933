import jwtDecode from "jwt-decode";

export function getToken(): string | null {
	const token = localStorage.getItem("token");
	if (!token) return null;
	
	const decoded: any = jwtDecode(token);
	if (decoded.exp && decoded.exp * 1000 < Date.now()) {
		localStorage.removeItem("token");
		window.location.href = "/login";
		return null;
	}

	return token;
}

export function isLoggedIn(): string | null {
  return getToken();
}

export enum Role {
  ADMIN = "ADMIN",
  USER = "USER",
  COMPANY = "COMPANY",
}

interface JwtPayload {
  id: number;
  code: string;
  role: Role;
}

export function checkRole(role: Role): boolean {
  const token = getToken();
  if (!token) return false;

  const { data } = jwtDecode(token) as { data: JwtPayload };
  if (!data) return false;
  return data.role === role;
}

export function getApplicationCode() {
  const token = getToken();
  if (!token) return undefined;

  const { data } = jwtDecode(token) as { data: JwtPayload };
  return data.code;
}

export function setToken(token: string | undefined) {
  if (!token) return;

  localStorage.setItem("token", token);
}

export function logout() {
  localStorage.removeItem("token");
}

export function getAuthHeader() {
  return {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  };
}
