export default function Companies() {
  return (
    <>
      <h2>Companii</h2>
      {/* Tabel + Coloanele tabelului */}
      {/* companies.map(c => <Company />) */}
    </>
  );
}

interface CompanyProps {
  id: number;
  name: string;
  logoUrl: string;
  descriptionRo: string;
  descriptionEn: string;
  authLink: string; // link pentru generarea contului
}

function Company({ id, name, logoUrl }: CompanyProps) {
  return <>{/* rand din tabel */}</>;
}
