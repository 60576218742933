import { Row, Col, Container, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { eEmail, eRequired } from "../../utils/validation_messages";
import { BeError, parseErrorToSweetAlert } from "../../utils/api_utils";
import Swal from "sweetalert2";
import * as Yup from "yup";
import styles from "./Contact.module.css";
import TargButton from "../../common/TargButton";
import { TextInput } from "../../common/Inputs";
import { contactRequest } from "./api";
import { useTranslation } from "react-i18next";

// https://www.figma.com/file/SWHby6nl2RlaGnOwxRJSDd/Site?node-id=4%3A1761&t=4z9Ab57qIZ5FP8YH-0
export default function Contact() {
  const { t } = useTranslation();

  return (
    <>
      <span id="contact" className="section"></span>
      <Container>
        <Row>
          <Col className="mb-4 px-4" xs={12} lg={6}>
            <h1 className="mb-4">{t("Contact_Title")}</h1>
            <p className="d-xs-none d-lg-block">{t("Contact_Description")}</p>
          </Col>
          <Col xs={12} lg={6}>
            <Container fluid className={`text-center ${styles.formContainer}`}>
              <h2>{t("Contact_Send")}</h2>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  message: "",
                }}
                validationSchema={Yup.object({
                  name: Yup.string()
                    .max(200)
                    .required(eRequired(t, t("Contact_Name"))),
                  email: Yup.string()
                    .email(eEmail(t))
                    .max(200)
                    .required(eRequired(t, t("Contact_Email"))),
                  message: Yup.string()
                    .max(20000)
                    .required(eRequired(t, t("Contact_Message"))),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    await contactRequest(values);
                    Swal.fire({
                      icon: "success",
                      title: t("Contact_PopupTitleSuccess"),
                    });
                    resetForm();
                  } catch (error: any) {
                    console.error(error);
                    Swal.fire(parseErrorToSweetAlert(t, error as BeError));
                  }
                  setSubmitting(false);
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <TextInput
                      name="name"
                      type="text"
                      placeholder={t("Contact_NamePlaceholder")}
                    />
                    <TextInput
                      name="email"
                      type="email"
                      placeholder={t("Contact_EmailPlaceholder")}
                    />
                    <TextInput
                      name="message"
                      type="text"
                      placeholder={t("Contact_MessagePlaceholder")}
                      rows={3}
                    />

                    {!isSubmitting ? (
                      <TargButton
                        variant="green"
                        type="submit"
                        className={styles.submitBtn}
                      >
                        {t("Contact_SendButton")}
                      </TargButton>
                    ) : (
                      <Spinner
                        className="mx-auto my-4"
                        style={{ color: "darkgreen" }}
                      />
                    )}
                  </Form>
                )}
              </Formik>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}
